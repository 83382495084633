import { types, flow, getRoot } from "mobx-state-tree";
import BusinessUnitsModel from "../BusinessUnits/BusinessUnitsModel";
import EmployeeModel from "../EmployeeManagement/EmployeeModel";

export default types
  .model("TimeCorrectionsModel", {
    id: types.optional(types.number, 0),
    employee_id: types.optional(types.maybeNull(types.number), 0),
    business_unit_id: types.optional(types.maybeNull(types.number), 0),
    hours: types.optional(types.maybeNull(types.number), 0),
    correction_from: types.optional(types.string, ""),
    correction_to: types.optional(types.string, ""),
    isCopy: types.optional(types.number, 0),
    comment: types.optional(types.maybeNull(types.string), ""),
    name: types.optional(types.string, ""),
    Employee: types.optional(EmployeeModel, {}),
    BusinessUnit: types.optional(BusinessUnitsModel, {}),
  })
  .views((self) => ({}));
