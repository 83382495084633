import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

let requestCount = 0;

NProgress.configure({ easing: 'ease', speed: 500 });

const decrementRequest = () => {
  requestCount = requestCount ? requestCount - 1 : requestCount;

  if (requestCount === 0) {
    NProgress.done();
  }
};

axios.interceptors.request.use(
  (config) => {
    if (requestCount === 0) {
      NProgress.start();
    }

    requestCount++;

    return config;
  },
  function (error) {
    decrementRequest();

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    decrementRequest();

    return response;
  },
  function (error) {
    decrementRequest();

    return Promise.reject(error);
  }
);
