import { types, flow, cast } from "mobx-state-tree";
import axios from "axios";
import DayListModel from "./DayListModel";
import WorkEmployeeModel from "./WorkEmployeeModel";
import TimeCorrectionsListModel from "./TimeCorrectionsListModel";

export default types
  .model("WorkControlState", {
    timeCorrections_list: types.optional(types.array(TimeCorrectionsListModel), []),
    total_stunden: types.optional(types.maybeNull(types.number), 0),
    singleState: types.optional(WorkEmployeeModel, {}),
    state: types.optional(types.array(DayListModel), []),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get filters() {
      return this.state.map((e) => ({ text: e.name, value: e.id }));
    },
  }))
  .actions((self) => ({
    SET_DATES: flow(function* (dates) {
      self.loading = true;
      self.state = cast(dates);

      self.loading = false;
    }),
    SET_TIMECORRECTIONS_LIST: flow(function* (list) {
      self.loading = true;
      self.timeCorrections_list = cast(list);
      self.total_stunden = list.reduce((total, item) => {
        return total + item.hours;
      }, 0);
      self.loading = false;
    }),
    SET_EMPLOYEE: flow(function* (data) {
      Object.assign(self.singleState, data);
      //self.singleState = cast(data);
    }),

    GET_PDF: flow(function* (params) {
      try {
        let response = yield axios.get(`/api/workControl/pdf_export`, { params, responseType: "blob" });

        return [response, null];
      } catch (error) {
        return [null, error];
      }
    }),

    // UPDATE: flow(function* (id, payload) {
    //   const { data } = yield axios.put(`/api/workHoursPercentages/${id}`, payload);
    //   self.state.splice(
    //     self.state.findIndex((data) => data.id === id),
    //     1,
    //     data
    //   );
    // }),

    // DELETE: flow(function* (id) {
    //   const { data } = yield axios.delete(`/api/workHoursPercentages/${id}`);
    //   self.state.splice(
    //     self.state.findIndex((data) => data.id === id),
    //     1
    //   );
    // }),

    // ADD: flow(function* (values) {
    //   const { data } = yield axios.post("/api/workHoursPercentages/", values);
    //   self.state.push(data);
    // }),

    FETCH_ABSENT_DATA: flow(function* (values) {
      const { isCopy, monthString, year } = values;
      let data = yield axios.get(`/api/workControl/getAbsences`, {
        params: {
          isCopy,
          monthString,
          year,
        },
      });
      return data;
    }),

    FETCH_TIMELOG_DATA: flow(function* (values) {
      const { isCopy, monthString, year, employeeNr, employeeId, categoryName } = values;
      self.loading = true;
      const { data } = yield axios.get(`/api/workControl/getValues`, {
        params: {
          isCopy,
          monthString,
          year,
          employeeNr,
          employeeId,
          categoryName,
        },
      });
      // Object.assign(self.state, data.finalData);
      self.state = cast(data.finalData);
      self.loading = false;
      return { periodeData: data.periodeData, analyzedData: data.analyzedData, total_essen: data.total_essen };
    }),

    GET_DATAS: flow(function* (values) {
      const { isCopy, monthString, year, employeeNr, employeeId } = values;
      const { data } = yield axios.get(`/api/workControl/getDatas`, {
        params: {
          isCopy,
          monthString,
          year,
          employeeNr,
          employeeId,
        },
      });
      // Object.assign(self.state, data.finalData);
      //self.state = cast(data.finalData);
      return data;
    }),
  }))

  .views((self) => ({}));
