const { types } = require("mobx-state-tree");

const BillingsAccountDaynameModel = types.model("BillingAccountDaynameModel", {
  amount: types.optional(types.maybeNull(types.number), 0),
  comment: types.optional(types.maybeNull(types.string), ""),
  date: types.optional(types.maybeNull(types.string), ""),
  dayname: types.optional(types.maybeNull(types.string), ""),
  groupId: types.optional(types.maybeNull(types.number), 0),
  groupName: types.optional(types.maybeNull(types.string), ""),
  name: types.optional(types.maybeNull(types.string), ""),
  order_no: types.optional(types.maybeNull(types.number), 0),
  pt_name: types.optional(types.maybeNull(types.string), ""),
  businessUnitName: types.optional(types.maybeNull(types.string), ""),
  isIncome: types.optional(types.maybeNull(types.number), 0),
});
export default BillingsAccountDaynameModel;
