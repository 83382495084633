import { types, flow, cast } from "mobx-state-tree";
import AbsenceManagementModel from "./AbsenceManagementModel";
import axios from "axios";

export default types
  .model("AbsenceState", {
    state: types.optional(types.array(AbsenceManagementModel), []),
    updatedAt: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    FETCH_DATA: flow(function* () {
      self.loading = true;

      const { data } = yield axios.get("/api/absences");
      self.state = cast(data);

      self.loading = false;
    }),

    FETCH_ONE_USER: flow(function* (id) {
      try {
        const { data } = yield axios.get(`/api/users/${id}`);
        //  self.state = cast(data);
      } catch (err) {
        throw err;
      }
      return true;
    }),

    FETCH_USER_TYPES: flow(function* () {
      const { data } = yield axios.get("/api/users/types");
      self.userTypes = cast(data);
    }),

    ADD: flow(function* (payload) {
      const { data } = yield axios.post("/api/absences", payload);
      self.state.push(data);
    }),

    UPDATE: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/absences/${id}`, values);
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1,
        data
      );
    }),

    DELETE: flow(function* (id) {
      yield axios.delete("/api/absences", { data: { ids: [id] } });
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
    }),
  }))
  .views((self) => ({}));
