import { types, getParent, hasParent } from "mobx-state-tree";

import UsersModel from "../UserManagement/UsersModel";
const BillingCommentsModel = types
  .model("BillingCommentsModel", {
    id: types.optional(types.number, 0),
    billing_id: types.optional(types.number, 0),
    user_id: types.optional(types.number, 0),
    comment: types.optional(types.maybeNull(types.string), ""),
    createdAt: types.optional(types.maybeNull(types.string), ""),
    updatedAt: types.optional(types.maybeNull(types.string), ""),
    business_unit_name: types.optional(types.maybeNull(types.string), ""),
    businessUnitName: types.optional(types.maybeNull(types.string), ""),
    commentDate: types.optional(types.maybeNull(types.string), ""),
    // User: types.optional(UsersModel, {}),
  })
  .views((self) => ({
    get local_unit_id() {
      if (hasParent(self, 5)) {
        const businessUnits = getParent(self, 5).businessUnits.state.filter((businessUnit) => businessUnit.id === self.billing_id);
        if (businessUnits[0]) {
          return businessUnits[0].local_unit_id;
        }
      } else {
        return 1;
      }
    },
  }));
export default BillingCommentsModel;
