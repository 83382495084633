import { types, flow, cast } from "mobx-state-tree";
import axios from "axios";
import ValueMappingModel from "./ValueMappingModel";
import FileSaver from "file-saver";
import FileDownload from "js-file-download";

export default types
  .model("ValueMappingState", {
    singleState: types.optional(ValueMappingModel, {}),
    state: types.optional(types.array(ValueMappingModel), []),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({}))
  .actions((self) => ({
    ADD: flow(function* (values) {
      try {
        self.loading = true;
        const { data } = yield axios.post("/api/valuemappings/add", values);
        const withKey = { ...data, key: data.id };
        self.state.unshift(withKey);
        self.loading = false;
      } catch (error) {}
      //self.state.push(addName);
    }),
    UPDATE: flow(function* (id, values) {
      try {
        self.loading = true;
        const { data: updatedData } = yield axios.put(`/api/valuemappings/${id}`, values);
        values = { ...values, ...updatedData };
        self.state.splice(
          self.state.findIndex((data) => data.id === id),
          1,
          values
        );
        self.loading = false;
      } catch (error) {
        console.log(error);
      }
    }),
    FETCH_DATA: flow(function* () {
      self.loading = true;
      const { data } = yield axios.get("/api/valuemappings/");
      const datWithKey = data?.map((d) => ({ ...d, key: d.id }));
      self.state = cast(datWithKey);
      self.loading = false;
    }),
    EXPORT_FIBU: flow(function* (date) {
      const { data } = yield axios.get(`/api/download/zeiterwaltung/${date}`);

      const fibu_data = data.map((_d) => [
        _d["Blg"],
        _d["Datum"],
        _d["Kto"],
        _d["S/H"],
        _d["Grp"],
        _d["GKto"],
        _d["SId"],
        _d["SIdx"],
        _d["KIdx"],
        _d["BTyp"],
        _d["MTyp"],
        _d["Code"],
        _d["Netto"],
        _d["Steuer"],
        _d["FW-Betrag"],
        _d["Tx1"],
        _d["Tx2"],
        _d["PkKey"],
        _d["OpId"],
        _d["Flag"],
      ]);

      let _csvContent = "";

      const _header = [
        "Blg",
        "Datum",
        "Kto",
        "S/H",
        "Grp",
        "GKto",
        "SId",
        "SIdx",
        "KIdx",
        "BTyp",
        "MTyp",
        "Code",
        "Netto",
        "Steuer",
        "FW-Betrag",
        "Tx1",
        "Tx2",
        "PkKey",
        "OpId",
        "Flag",
      ].join(",");

      _csvContent += _header + "\r\n";

      for (const _tLD of fibu_data) {
        let row = _tLD.join(",");
        _csvContent += row + "\r\n";
      }
      const BOM = "\uFEFF";
      _csvContent = BOM + _csvContent;
      const _blob = new Blob([_csvContent], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(_blob, `Überstunden/Ferien FiBu ${date}.csv`);
      //change this
      //FileDownload(data, `Überstunden/Ferien FiBu ${date}.csv`, "text/csv;charset=utf-8");
      //FileDownload(_blob, `Überstunden/Ferien FiBu ${date}.csv`, "text/csv;charset=utf-8");
    }),
  }))

  .views((self) => ({}));
