import { types, flow, cast } from "mobx-state-tree";

const WorkHoursPercentageModel = types
   .model("WorkHoursPercentageModel", {
      id: types.optional(types.number, 0),
      week_no: types.optional(types.number, 0),
      year: types.optional(types.number, 0),
      percent: types.optional(types.number, 0),
   })
   .views((self) => ({}))
   .actions((self) => ({}));

export default WorkHoursPercentageModel;
