import { types, flow, cast, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import BusinessUnitsModel from './BusinessUnitsModel';

const BusinessUnitsState = types
	.model('BusinessUnitsState', {
		state: types.optional(types.array(BusinessUnitsModel), []),
		updatedAt: types.optional(types.string, ''),
		loading: types.optional(types.boolean, false),
	})
	.views((self) => ({
		get filters() {
			return this.state.map((e) => ({ text: e.name, value: e.id }));
		},
		get assignedBusinessUnits() {
			const rootStore = getRoot(self);
			console.log(rootStore.login.businessUnits.toJSON());
			return rootStore.login.user_type_id === 2
				? self.state.filter((businessUnit) => rootStore.login.businessUnits.find((e) => e.id === businessUnit.id))
				: self.state;
		},
	}))
	.actions((self) => ({
		RESET_BUSINESSUNITS(value) {
			self.employee = [];
		},

		SET_UPDATED_AT() {
			self.updatedAt = new Date().getTime().toString();
		},

		FETCH_DATA: flow(function* (isActive, user_id = '') {
			self.loading = true;

			const { data } = yield axios.get(`/api/businessUnits/${isActive ? 'active' : ''}/${user_id}`);
			self.state = cast(data);

			self.loading = false;
		}),

		ADD: flow(function* (values) {
			const { data } = yield axios.post('/api/businessUnits', values);
			self.state.push(data);
		}),

		UPDATE: flow(function* (id, values) {
			const { data } = yield axios.put(`/api/businessUnits/${id}`, values);
			self.state.splice(
				self.state.findIndex((data) => data.id === id),
				1,
				data
			);
		}),

		DELETE: flow(function* (id) {
			yield axios.delete('/api/businessUnits', { data: { ids: [id] } });
			self.state.splice(
				self.state.findIndex((data) => data.id === id),
				1
			);
		}),
	}));
export default BusinessUnitsState;
