import { types, flow } from 'mobx-state-tree';
import axios from 'axios';

const EmployeeBusinessUnitsModel = types
	.model('EmployeeBusinessUnitsModel', {
		id: types.optional(types.number, 0),
		employee_id: types.optional(types.number, 0),
		business_unit_id: types.optional(types.number, 0),
		isActive: types.optional(types.number, 0),
	})
	.actions((self) => ({
		UPDATE: flow(function* (id, values) {
			const { data } = yield axios.put(`/api/businessUnits/employeeBusinessUnit/${id}`, values);
			self = Object.assign(self, data);
		}),

		setIsActive(isActive) {
			self.isActive = isActive;
		},
	}));
export default EmployeeBusinessUnitsModel;
