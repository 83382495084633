import React from 'react';
import { Spin, Space } from 'antd';

const Spinner = () => (
	<Space size='middle' style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
		<Spin size='large' style={{ margin: '0 auto' }} />
	</Space>
);

export default Spinner;
