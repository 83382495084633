import { types, flow, getParent, getRoot } from "mobx-state-tree";
import BusinessUnitsModel from "../BusinessUnits/BusinessUnitsModel";
import BillingAccountColumnsModel from "./BillingAccountColumnsModel";
import BillingAccountsModel from "./BillingAccountsModel";
import BillingCommentsModel from "./BillingCommentsModel";
import axios from "axios";
import UserTypesModel from "../UserManagement/UserTypesModel";
import ApiWrapper from "../../utils/ApiWrapper";
import { toJS } from "mobx";
import BillingsAccountDaynameModel from "./BillingsAccountDaynameModel";

export default types
  .model("CalculationModel", {
    id: types.optional(types.number, 0),
    business_unit_id: types.optional(types.number, 0),
    status: types.optional(types.number, 0),
    date: types.optional(types.string, ""),
    BillingStaticAccounts: types.optional(types.maybeNull(types.array(BillingAccountsModel)), []),
  })
  .views((self) => ({}))
  .actions((self) => ({}));
