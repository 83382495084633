import { types, flow, cast, getRoot } from "mobx-state-tree";
import TimeManagementModel from "./TimeManagementModel";
import FileSaver from "file-saver";
import FileDownload from "js-file-download";
import axios from "axios";

export default types
  .model("TimelogsState", {
    state: types.optional(types.array(TimeManagementModel), []),
    status: types.optional(types.number, 0),
    user_type: types.optional(types.string, ""),
    updatedAt: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get employee_filters() {
      const temp = [];

      this.state
        .map((e) => ({ text: e.work_name, value: e.employee_id }))
        .sort((a, b) => a - b)
        .forEach((e) => {
          if (temp.length !== 0) {
            if (!temp.find((emp) => emp.value === e.value)) temp.push(e);
          } else {
            temp.push(e);
          }
        });

      return temp;
    },

    get local_unit_filters() {
      const temp = [];
      this.state
        .map((e) => ({ text: e.BusinessUnit.LocalUnit.name, value: e.BusinessUnit.LocalUnit.id }))
        .sort((a, b) => a - b)
        .forEach((e) => {
          if (temp.length !== 0) {
            if (!temp.find((emp) => emp.value === e.value)) temp.push(e);
          } else {
            temp.push(e);
          }
        });

      return temp;
    },

    get business_unit_filters() {
      const temp = [];
      this.state
        .map((e) => ({ text: e.BusinessUnit.name, value: e.BusinessUnit.id }))
        .sort((a, b) => a - b)
        .forEach((e) => {
          if (temp.length !== 0) {
            if (!temp.find((emp) => emp.value === e.value)) temp.push(e);
          } else {
            temp.push(e);
          }
        });

      return temp;
    },

    get currentStatus() {
      if (this.state.length === 0) {
        return 1;
      } else {
        return Math.min(...this.state.map((e) => e.status));
      }
    },

    get userType() {
      let user_type = "none";
      if (this.state.length === 0) {
        return user_type;
      } else {
        this.state.forEach((e) => {
          if (e.user_type_id) user_type = e.UserType.name;
        });
      }

      return user_type;
    },

    get businessUnits() {
      return this.state.reduce((a, b) => {
        const bu = b?.BusinessUnit;
        return (a = a.concat(!a.find((_bu) => bu?.id === _bu.id) ? bu : []));
      }, []);
    },
  }))
  .actions((self) => ({
    SET(property, value) {
      self[property] = value;
    },

    FETCH_DATA: flow(function* (date, user_id = "") {
      self.loading = true;

      const { data } = yield axios.get(`/api/timelogs/${date}/${user_id}`);
      self.state = cast(data.data);
      self.status = data.status;
      self.user_type = data.user_type;

      self.loading = false;
    }),

    EXPORT_DATA: flow(function* ({ name, rangeDate, type = "csv", monthYearDate }, exportNo) {
      if (exportNo === 1) {
        const { data } = yield axios.get(`/api/download/timelogs/${type}/${rangeDate[0]}/${rangeDate[1]}`, { responseType: "blob" });
        FileDownload(data, `Time Report (${rangeDate[0]} - ${rangeDate[1]}).csv`);
      } else {
        const { data } = yield axios.post(`/api/download/timelogs/${type}/${monthYearDate}`, { name });

        const _timelogData = data.newData.map((_d) => [
          _d["Blg"],
          _d["Datum"],
          _d["Kto"],
          _d["S/H"],
          _d["Grp"],
          _d["Gkto"],
          _d["SId"],
          _d["SIdx"],
          _d["KIdx"],
          _d["BTyp"],
          _d["MTyp"],
          _d["Code"],
          _d["Netto"],
          _d["Steuer"],
          _d["FW-Betrag"],
          _d["Tx1"],
          _d["Tx2"],
          _d["PkKey"],
          _d["OpId"],
          _d["Flag"],
        ]);

        let _csvContent = "";

        const _header = [
          "Blg",
          "Datum",
          "Kto",
          "S/H",
          "Grp",
          "Gkto",
          "SId",
          "SIdx",
          "KIdx",
          "BTyp",
          "MTyp",
          "Code",
          "Netto",
          "Steuer",
          "FW-Betrag",
          "Tx1",
          "Tx2",
          "PkKey",
          "OpId",
          "Flag",
        ].join(",");

        _csvContent += _header + "\r\n";

        for (const _tLD of _timelogData) {
          let row = _tLD.join(",");
          _csvContent += row + "\r\n";
        }
        const BOM = "\uFEFF";
        _csvContent = BOM + _csvContent;
        const _blob = new Blob([_csvContent], { type: "text/csv;charset=utf-8" });

        FileSaver.saveAs(_blob, `Time Report (${monthYearDate}).csv`);
        // FileDownload(data, `Time Report (${monthYearDate}).csv`);
      }
    }),

    FIND_LOCALUNITS: flow(function* (id) {
      const result = self.state.find((unit) => unit.id === id);
      return result;
    }),

    ADD: flow(function* (values) {
      const { data } = yield axios.post("/api/timelogs", values);
      data.new = true;
      self.state.unshift(data);
      return data;
    }),

    UPDATE_RELEASE: flow(function* (date, values, user_id = "0") {
      const user = getRoot(self).login;
      const { data } = yield axios.put(`/api/timelogs/release/${date}/${user_id}`, values);
      self.user_type = user.type;
      self.status = values.status;
      self.state = cast(data.data);
    }),

    DELETE: flow(function* (id) {
      yield axios.delete("/api/timelogs", { data: { ids: [id] } });
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
    }),
  }));
