import { types, flow, getRoot } from "mobx-state-tree";
import BusinessUnitsModel from "../BusinessUnits/BusinessUnitsModel";
import EmployeeModel from "../EmployeeManagement/EmployeeModel";
import SettingsModel from "../DropdownSettings/DropdownSettingModel";
import UserTypesModel from "../UserManagement/UserTypesModel";
import axios from "axios";
import moment from "moment";

export default types
  .model("TimelogsModel", {
    id: types.optional(types.number, 0),
    employee_id: types.optional(types.maybeNull(types.number), 0),
    settings_id: types.optional(types.maybeNull(types.number), 0),
    business_unit_id: types.optional(types.maybeNull(types.number), 0),
    local_unit_id: types.optional(types.maybeNull(types.number), 0),
    start_time: types.optional(types.maybeNull(types.string), ""),
    end_time: types.optional(types.maybeNull(types.string), ""),
    pause_time: types.optional(types.maybeNull(types.string), ""),
    meal_deduct: types.optional(types.maybeNull(types.number), 0),
    date: types.optional(types.string, ""),
    status: types.optional(types.maybeNull(types.number), 0),
    user_type_id: types.optional(types.maybeNull(types.number), 0),
    UserType: types.optional(types.maybeNull(UserTypesModel), {}),
    Employee: types.optional(EmployeeModel, {}),
    BusinessUnit: types.optional(BusinessUnitsModel, {}),
    Settings: types.optional(types.maybeNull(SettingsModel), {}),
    new: types.optional(types.maybeNull(types.boolean), false),
  })
  .views((self) => ({
    get key() {
      return self.id;
    },

    get name() {
      return self.Employee.first_name + " " + self.Employee.last_name;
    },

    get work_name() {
      return self.Employee.work_name;
    },

    get local_unit_name() {
      return self.BusinessUnit.LocalUnit.name;
    },

    get business_unit_name() {
      return self.BusinessUnit.name;
    },

    get settings_name() {
      return self.Settings?.name || "";
    },

    get wage_carry_over() {
      const date = moment.duration({ hours: self.end_time.substring(0, 2), minutes: self.end_time.substring(3) });

      if (parseInt(self.end_time) - parseInt(self.start_time) < 0) {
        // return (this.converter(self.end_time) - this.converter(self.start_time) - this.converter('00' + self.pause_time) + 24).toFixed(2);
        date.add(24, "hours");
        date.subtract(self.start_time, "minutes");
        date.subtract(self.pause_time, "minutes");
      } else {
        // return (this.converter(self.end_time) - this.converter(self.start_time) - this.converter('00' + self.pause_time)).toFixed(2);
        date
          .subtract({ hours: self.start_time.substring(0, 2), minutes: parseInt(self.start_time.substring(3)) })
          .subtract({ minutes: parseInt(self.pause_time) });
      }

      const minutes = parseFloat(parseInt(date.minutes()) / 60)
        .toFixed(2)
        .split(".")[1];

      // return (hours / 24 + minutes / 1440).toFixed(2);
      return ("0" + date.asHours().toString().split(".")[0]).slice(-2) + "." + ("00" + minutes).slice(-2);
    },
  }))
  .actions((self) => ({
    SET(property, value) {
      self[property] = value;
    },

    converter(time) {
      const hours = parseInt(time.slice(0, 2));
      const min = parseInt(time.slice(-2));
      return hours / 24 + min / 1440;
    },

    UPDATE: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/timelogs/${id}`, values);
      self = Object.assign(self, data);
    }),
  }));
