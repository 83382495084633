import { types, flow, cast } from 'mobx-state-tree';

import EmployeeModel from '../EmployeeManagement/EmployeeModel';
export default types
  .model('BillingAccountColumnsModel', {
    id: types.optional(types.number, 0),
    employee_id: types.optional(types.maybeNull(types.number), 0),
    name: types.optional(types.maybeNull(types.string), ''),
    type: types.optional(types.maybeNull(types.string), ''),
    isStatic: types.optional(types.number, 0),
    // Employee: types.optional(types.maybeNull(EmployeeModel), {}),
  })
  .views((self) => ({
    get key() {
      return self.id;
    },
    get col_name() {
      if (self.employee_id != null) {
        return self.Employee.first_name + ' ' + self.Employee.last_name;
      }
      return self.name;
    },
  }));
