import { types, flow, cast } from "mobx-state-tree";
import AccountsModel from "../Accounts/AccountsModel";
import GroupAccountModel from "./GroupAccountModel";
import axios from "axios";

const AccountCategoriesModel = types
  .model("AccountCategoriesModel", {
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    order_no: types.optional(types.number, 0),
    account: types.optional(types.number, 0),
    isActive: types.optional(types.number, 1),
    Accounts: types.optional(types.array(AccountsModel), []),
  })
  .views((self) => ({
    get index() {
      return self.order_no;
    },

    get accountNames() {
      return this.Accounts.map((e) => e.name).join(", ");
    },
  }))
  .actions((self) => ({
    SET(property, value) {
      self[property] = value;
    },

    UPDATE: flow(function* (values, isAccountSort = true, id = -1) {
      let payload = values;

      if (isAccountSort) {
        payload = {
          GroupAccount: values.map((e, i) => ({ id: e.GroupAccounts.id, account_id: e.id, group_id: e.GroupAccounts.group_id, order_no: i })),
          isAccountSort,
        };
        self.Accounts = cast(
          values.map((e, i) => {
            id = e.GroupAccounts.group_id;
            e.GroupAccounts.SET("order_no", i);
            return e;
          })
        );
      }
      const { data } = yield axios.put(`/api/groups/${id}`, payload);
      if (!isAccountSort) self = Object.assign(self, data);
    }),

    CHANGE_STATUS: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/groups/${id}`, values);
      console.log("🚀 ~ file: AccountCategoriesModel.js ~ line 23 ~ CHANGE_STATUS:flow ~ data", data);
      self.isActive = values.isActive;
    }),
  }));

export default AccountCategoriesModel;
