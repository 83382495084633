import { types, flow, cast, getRoot } from "mobx-state-tree";
import TimeCorrectionsModel from "./TimeCorrectionsModel";
import cloneDeep from "clone-deep";

import axios from "axios";
import moment from "moment";

export default types
  .model("TimeCorrectionState", {
    state: types.optional(types.array(TimeCorrectionsModel), []),
    status: types.optional(types.number, 0),
    user_type: types.optional(types.string, ""),
    updatedAt: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({}))
  .actions((self) => ({
    ADD: flow(function* (values) {
      try {
        self.loading = true;
        const { data } = yield axios.post("/api/timecorrections/add", values);
        const addName = { ...data, name: `${data.Employee.first_name} ${data.Employee.last_name}` };
        self.state.unshift(addName);
        self.loading = false;
      } catch (error) {}
      //self.state.push(addName);
    }),
    UPDATE: flow(function* (id, values, employees, modalState) {
      try {
        self.loading = true;
        const { data: updatedData } = yield axios.put(`/api/timecorrections/${id}`, values);
        values.correction_from = moment(values.correction_from).add("days", 1).format("YYYY-MM-DD");
        values.correction_to = moment(values.correction_to).add("days", 1).format("YYYY-MM-DD");

        //let filteredEmployees = self.getFilteredEmployees(employees);

        // let currentEmployee = filteredEmployees.find((data) => data.id === modalState.employeeId);
        //  console.log(modalState, currentEmployee, data, "valuesvaluesvaluesralp");
        // let newBusinessUnit = currentEmployee.BusinessUnits.find((c) => c.id === values.business_unit_id);
        // newBusinessUnit = cloneDeep(newBusinessUnit);
        // values = { ...values, BusinessUnit: newBusinessUnit, id: id };
        values = { ...values, ...updatedData };
        self.state.splice(
          self.state.findIndex((data) => data.id === id),
          1,
          values
        );
        self.loading = false;
      } catch (error) {
        console.log(error, "valuesvaluesvaluesralp");
      }
    }),
    DELETE: flow(function* (id) {
      self.loading = true;
      const { result } = yield axios.delete("/api/timecorrections/delete", { data: { ids: [id] } });
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
      self.loading = false;
    }),
    FETCH_DATA: flow(function* () {
      self.loading = true;
      const data = yield axios.get("/api/timecorrections/");
      const addName = data.data.map((item) => {
        return { ...item, name: `${item.Employee?.first_name} ${item.Employee?.last_name}`, isCopy: item.Employee?.isCopy };
      });

      self.state = cast(addName);
      self.loading = false;
    }),

    FILTER_DATA: flow(function* (month, year) {
      self.loading = true;
      const _state = self.state.filter((_ps) => {
        const _start = new Date(_ps.correction_from);
        const _end = new Date(_ps.correction_to);
        if ((_start.getMonth() === month && _start.getFullYear() === year) || (_end.getMonth() === month && _end.getFullYear() === year)) {
          return true;
        }
        return false;
      });
      self.state = cast(_state);
      self.loading = false;
    }),

    getLatestId: (eObj, source) => {
      const filteredArr = source.filter((_a) => _a.isCopy === eObj.isCopy);

      return filteredArr[filteredArr.length - 1].id;
    },

    getFilteredEmployees: (employees) => {
      const seen = new Set();
      const filteredArr = employees.state.filter((el) => {
        const duplicate = seen.has(el.isCopy);
        seen.add(el.isCopy);
        return !duplicate;
      });

      for (const _fA of filteredArr) {
        const _id = self.getLatestId(_fA, employees.state);

        _fA._uid = _id;
      }
      let activeEmployees = filteredArr.filter((c) => c.isActive);

      return activeEmployees;
    },
  }));
