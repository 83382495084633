import { types, flow, cast } from 'mobx-state-tree';
import GroupAccountModel from '../Groups/GroupAccountModel';

const OrderDetailsModel = types
  .model('OrderDetailsModel', {
    id: types.optional(types.number, 0),
    account_id: types.optional(types.number, 0),
    group_id: types.optional(types.number, 0),
    order_no: types.optional(types.number, 0),
    Group: types.optional(GroupAccountModel, {}),
  })
  .actions((self) => ({}));
export default OrderDetailsModel;
