import { types, flow, cast } from 'mobx-state-tree';

const AccountBusinessUnitsModel = types
    .model('AccountBusinessUnitsModel', {
        id: types.optional(types.number, 0),
        account_id: types.optional(types.number, 0),
        business_unit_id: types.optional(types.number, 0),
    })
    .actions((self) => ({}));
export default AccountBusinessUnitsModel;
