import { types, flow, cast } from "mobx-state-tree";
import moment from "moment";
import axios from "axios";

const WorkEmployeeModel = types
  .model("WorkEmployeeModel", {
    street: types.optional(types.maybeNull(types.string), ""),
    zip: types.optional(types.maybeNull(types.string), ""),
    rate: types.optional(types.maybeNull(types.number), 0),
    address: types.optional(types.maybeNull(types.string), ""),
    employee_nr: types.optional(types.maybeNull(types.string), ""),
    employee_category: types.optional(types.maybeNull(types.string), ""),
    id: types.optional(types.maybeNull(types.number), 0),
    mobile_no: types.optional(types.maybeNull(types.string), ""),
    first_name: types.optional(types.maybeNull(types.string), ""),
    last_name: types.optional(types.maybeNull(types.string), ""),
    work_name: types.optional(types.maybeNull(types.string), ""),
    local_unit_id: types.optional(types.maybeNull(types.number), 0),
    default_business_unit_id: types.optional(types.maybeNull(types.number), 0),
    default_settings_id: types.optional(types.maybeNull(types.number), 0),
    valid_from: types.optional(types.maybeNull(types.string), ""),
    isCopy: types.optional(types.maybeNull(types.number), 0),
    isActive: types.optional(types.maybeNull(types.number), 0),
    start_date: types.optional(types.maybeNull(types.string), ""),
    end_date: types.optional(types.maybeNull(types.string), ""),
    comment: types.optional(types.maybeNull(types.string), ""),
    birth_date: types.optional(types.maybeNull(types.string), ""),
    ahv_nr: types.optional(types.maybeNull(types.string), ""),
    h_woche: types.optional(types.maybeNull(types.number), 0),
    total_essen: types.optional(types.maybeNull(types.number), 0),
    disabled: types.optional(types.maybeNull(types.number), 0),
    //additional header displays
    feiertage_total: types.optional(types.maybeNull(types.number), 0),
    ferientage_total: types.optional(types.maybeNull(types.number), 0),
    ruhetag_total: types.optional(types.maybeNull(types.number), 0),
    uberstunden_total: types.optional(types.maybeNull(types.number), 0),
    uberzeit_total: types.optional(types.maybeNull(types.number), 0),
    total_days: types.optional(types.maybeNull(types.number), 0),
    ferien_gutschrift: types.optional(types.maybeNull(types.number), 0),
    category_name: types.optional(types.maybeNull(types.string), ""),
    //ruhetage
    ruhetag_jahr_total: types.optional(types.maybeNull(types.number), 0),
    ruhetag_gutschrift_total: types.optional(types.maybeNull(types.number), 0),
    ruhetag_ubertrag_total: types.optional(types.maybeNull(types.number), 0),
    ruhetag_bezug_total: types.optional(types.maybeNull(types.number), 0),
    ruhetag_rest_total: types.optional(types.maybeNull(types.number), 0),
    //ferientage
    ferientage_jahr_total: types.optional(types.maybeNull(types.number), 0),
    ferientage_gutschrift_total: types.optional(types.maybeNull(types.number), 0),
    ferientage_ubertrag_total: types.optional(types.maybeNull(types.number), 0),
    ferientage_bezug_total: types.optional(types.maybeNull(types.number), 0),
    ferientage_rest_total: types.optional(types.maybeNull(types.number), 0),
    //feiertage
    feiertage_jahr_total: types.optional(types.maybeNull(types.number), 0),
    feiertage_gutschrift_total: types.optional(types.maybeNull(types.number), 0),
    feiertage_ubertrag_total: types.optional(types.maybeNull(types.number), 0),
    feiertage_bezug_total: types.optional(types.maybeNull(types.number), 0),
    feiertage_rest_total: types.optional(types.maybeNull(types.number), 0),
    //otherData
    soll_h_mte_total: types.optional(types.maybeNull(types.number), 0),
    gel_stunden_total: types.optional(types.maybeNull(types.number), 0),
    monat_mehr_uberstunden_total: types.optional(types.maybeNull(types.number), 0),
    //Periode Data
    gesamt_uberstunden: types.optional(types.maybeNull(types.number), 0),
    gesamt_uberzeit: types.optional(types.maybeNull(types.number), 0),
    saldo_uberstunden: types.optional(types.maybeNull(types.number), 0),
    saldo_uberzeit: types.optional(types.maybeNull(types.number), 0),
    uezvormonat_uberstunden: types.optional(types.maybeNull(types.number), 0),
    uezvormonat_uberzeit: types.optional(types.maybeNull(types.number), 0),
    //Periode dates
    selected_month_periode: types.optional(types.maybeNull(types.string), ""),
    previous_month_periode: types.optional(types.maybeNull(types.string), ""),
    month_selected: types.optional(types.maybeNull(types.number), 0),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default WorkEmployeeModel;
