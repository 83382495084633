import { types, flow, cast, getRoot } from "mobx-state-tree";
import CreditsAndReductionsModel from "./CreditsAndReductionsModel";
import CrTypesModel from "./CrTypesModel";

import axios from "axios";
import moment from "moment";

export default types
  .model("CreditsAndReductionsState", {
    state: types.optional(types.array(CreditsAndReductionsModel), []),
    crTypes: types.optional(types.array(CrTypesModel), []),
    status: types.optional(types.number, 0),
    user_type: types.optional(types.string, ""),
    updatedAt: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({}))
  .actions((self) => ({
    ADD: flow(function* (values) {
      self.loading = true;
      const { data } = yield axios.post("/api/creditsandreductions/add", values);
      const addName = { ...data, name: `${data.Employee.first_name} ${data.Employee.last_name}` };
      self.state.unshift(addName);
      self.loading = false;
    }),
    UPDATE: flow(function* (id, values) {
      const format = "DD.MM.YYYY";
      self.loading = true;
      const { data } = yield axios.put(`/api/creditsandreductions/${id}`, values);
      //override cr_date to match the display output in the table with the inputted date value
      values.cr_date = moment(values.cr_date).add("days", 1).format("YYYY-MM-DD");
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1,
        data
      );
      self.loading = false;
    }),
    DELETE: flow(function* (id) {
      self.loading = true;
      const { result } = yield axios.delete("/api/creditsandreductions/delete", { data: { ids: [id] } });
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
      self.loading = false;
    }),
    FETCH_DATA: flow(function* () {
      self.loading = true;
      const data = yield axios.get("/api/creditsandreductions/");

      const addName = data.data.map((item) => {
        return { ...item, name: `${item.Employee.first_name} ${item.Employee.last_name}`, isCopy: item.Employee.isCopy };
      });
      self.state = cast(addName);
      self.loading = false;
    }),
    FETCH_CRTYPES: flow(function* () {
      const data = yield axios.get("/api/creditsandreductions/crfetch");
      self.crTypes = cast(data.data);
    }),
    FILTER_DATA: flow(function* (month, year) {
      self.loading = true;
      const _state = self.state.filter((_ps) => {
        const _start = new Date(_ps.cr_date);

        if (_start.getMonth() === month && _start.getFullYear() === year) {
          return true;
        }
        return false;
      });

      self.state = cast(_state);
      self.loading = false;
    }),
  }));
