import { types, flow, cast } from "mobx-state-tree";

const DayListModel = types
  .model("ValueMappingModel", {
    id: types.optional(types.number, 0),
    name: types.optional(types.maybeNull(types.string), ""),
    description: types.optional(types.maybeNull(types.string), ""),
    value: types.optional(types.maybeNull(types.number), 0),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default DayListModel;
