/**
 *   ====== User Types ======
 *   1 = Administrator
 *   2 = Standard User
 *   3 = Finance User
 *   4 = HR User
 *   5 = HR & Finance User
 */

const routes = {
  Administrator: [1],
  Tagesabrechnung: [1, 2, 3, 5],
  Employee: [1, 2, 4, 5],
  TimeManagement: [1, 2, 4, 5],
  Absence: [1, 4, 5],
  WorkControl: [1, 4],
  ValueMapping: [1, 3, 5],
};

export default Object.freeze(routes);
