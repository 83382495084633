import { types, flow, getRoot } from 'mobx-state-tree';
import BusinessUnitsModel from '../BusinessUnits/BusinessUnitsModel';
import axios from 'axios';

const LocalUnitsModel = (isShowBusinessUnit) => {
	const additionalProperty = isShowBusinessUnit ? { BusinessUnits: types.optional(types.array(BusinessUnitsModel), []) } : {};

	return types
		.model('LocalUnitsModel', {
			id: types.optional(types.number, 0),
			name: types.optional(types.string, ''),
			kostentelle: types.optional(types.maybeNull(types.number), 0),
			account_soll_haben: types.optional(types.maybeNull(types.string), ''),
			address: types.optional(types.string, ''),
			...additionalProperty,
		})
		.views((self) => ({
			get key() {
				return self.id;
			},

			get unit_type_id() {
				return 0;
			},

			get isActive() {
				return (!!this.BusinessUnits ? this.BusinessUnits.some((e) => e.isActive) : false) ? 1 : 0;
			},

			get businessUnitNames() {
				return this.BusinessUnits.reduce((a, b) => (a += b.name + ' '), '');
			},
		}))
		.actions((self) => ({
			SET(property, value) {
				self[property] = value;
			},

			ADD: flow(function* (values) {
				const { data } = yield axios.post('/api/businessUnits', values);
				self.BusinessUnits.push({ ...data, isActive: 1 });
			}),

			UPDATE: flow(function* (id, values) {
				const { data } = yield axios.put(`/api/businessUnits/${id}`, values);
				self.BusinessUnits.splice(
					self.BusinessUnits.findIndex((data) => data.id === id),
					1,
					data
				);
			}),
		}));
};
export default LocalUnitsModel;
