import { types, flow, cast, getParent, getRoot } from "mobx-state-tree";
import axios from "axios";
import AccountsModel from "./AccountsModel";

export default types
  .model("AccountsSTATE", {
    state: types.optional(types.array(AccountsModel), []),
    updatedAt: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    availableAccount(account_type_id, account_id) {
      const BillingAccounts = getParent(self).billings.billing.BillingAccounts.toJSON();

      return this.state
        .filter((e) => e.account_type_id === account_type_id && e.isActive)
        .filter((e) => !BillingAccounts.find((account) => account.account_id === e.id) || account_id === e.id);
    },

    hasRemainingAccount(account_type_id) {
      const BillingAccounts = getParent(self).billings.billing.BillingAccounts.toJSON();
      // console.log(
      // 	this.state
      // 		.filter((e) => e.account_type_id === account_type_id && e.isActive)
      // 		.filter((e) => !BillingAccounts.find((account) => account.account_id === e.id)).length !== 0
      // );
      return (
        this.state
          .filter((e) => e.account_type_id === account_type_id && e.isActive)
          .filter((e) => !BillingAccounts.find((account) => account.account_id === e.id)).length !== 0
      );
    },

    getNextAccount(account_type_id) {
      const BillingAccounts = getParent(self).billings.billing.BillingAccounts.toJSON();
      return this.state
        .filter((e) => e.account_type_id === account_type_id && e.isActive)
        .filter((e) => !BillingAccounts.find((account) => account.account_id === e.id))[0];
    },
  }))
  .actions((self) => ({
    RESET_ACCOUNT() {
      self.state = [];
    },

    FETCH_DATA: flow(function* (account_type_id = "") {
      self.loading = true;

      const { data } = yield axios.get(`/api/accounts/${account_type_id}`);
      self.state = cast(data);

      self.loading = false;
    }),

    FETCH_ACCOUNT_SOLL_HABEN: flow(function* (account_type_id = "") {
      const { data } = yield axios.get(`/api/accounts/debit_credit`);

      return data;
    }),

    FETCH_PAYMENT_TYPES: flow(function* () {
      const { data } = yield axios.get("/api/accounts/types");
      self.paymentTypes = cast(data);
    }),

    ADD: flow(function* (values) {
      const { data } = yield axios.post("/api/accounts/", values);
      self.state.push(data);
    }),

    DELETE: flow(function* (id) {
      yield axios.delete("/api/accounts", { data: { ids: [id] } });
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
    }),

    UPDATE: flow(function* (values) {
      const payload = values.map((e, i) => ({ ...e, order_no: i }));
      self.state = cast(
        values.map((e, i) => {
          e.SET("order_no", i);
          return e;
        })
      );

      yield axios.put("/api/accounts/", payload);
    }),

    TOGGLE_STATUS: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/accounts/${id}`, values);
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1,
        data
      );
    }),
  }));
