import { types, flow, getRoot } from 'mobx-state-tree';
import axios from 'axios';

export default types
  .model('OtherSettingsModel', {
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
  })
  .actions((self) => ({
    SET(property, value) {
      self[property] = value;
    },

    UPDATE: flow(function* (values) {
      const { data } = yield axios.put(`/api/otherSettings/${self.id}`, values);
      self.value = data.value
    }),
  }));
