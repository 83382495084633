import { types, flow, cast } from 'mobx-state-tree';

import BillingAccountColumnsModel from './BillingAccountColumnsModel';
const BillingAccountValuesModel = types
  .model('BillingAccountValuesModel', {
    id: types.optional(types.number, 0),
    business_unit_id: types.optional(types.maybeNull(types.number), 0),
    business_unit_name: types.optional(types.maybeNull(types.string), ''),
    billing_account_id: types.optional(types.number, 0),
    billing_account_columns_id: types.optional(types.number, 0),
    qty: types.optional(types.maybeNull(types.number), 0),
    amount: types.optional(types.maybeNull(types.number), 0),
  })
  .actions((self) => ({}));
export default BillingAccountValuesModel;
