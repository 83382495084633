import { types, flow, getRoot, cast } from "mobx-state-tree";
import EmployeeModel from "../EmployeeManagement/EmployeeModel";
import SettingsModel from "../DropdownSettings/DropdownSettingModel";
import axios from "axios";

const UsersModel = types
  .model("AbsenceModel", {
    id: types.optional(types.number, 0),
    employee_id: types.optional(types.number, 0),
    reason_id: types.optional(types.number, 0),
    percentage_provided: types.optional(types.number, 100),
    date_of_absence: types.optional(types.string, ""),
    end_date_of_absence: types.optional(types.string, ""),
    Employee: types.optional(types.maybeNull(EmployeeModel), {}),
    Settings: types.optional(types.maybeNull(SettingsModel), {}),
  })
  .views((self) => ({
    get name() {
      return self.Employee.first_name + " " + self.Employee.last_name;
    },

    get reason_desc() {
      return self.Settings?.name || "";
    },
  }))
  .actions((self) => ({
    SET_STATE(values) {
      console.log(self);
      self = {
        ...self,
        ...values,
      };
    },

    UPDATE: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/absences/${id}`, values);
      self = Object.assign(self, data);
    }),
  }));

export default UsersModel;
