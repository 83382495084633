import React, { useState, useLayoutEffect } from 'react';
import Sidebar from '../Layout/Sidebar';

import { observer, inject } from 'mobx-react';

import UserInfo from '../Layout/UserInfo';
import { Layout, Divider, Row, Col, Button, Affix, message } from 'antd';
//translation
import { useTranslation } from 'react-i18next';

const { Content, Footer, Sider } = Layout;

const MainLayout = (props) => {
	const { t } = useTranslation('common'); //translation

	const { store, adminOnly } = props;

	const user = props.store.login;
	const company = props.store.company;
	const [isAffixed, setIsAffixed] = useState(false);
	const [IsReady, setIsReady] = useState(false);
	const [IsFormVisible, setIsFormVisible] = useState(false);
	const [IsDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
	const [downloadingCSV, setDownloadingState] = useState(false);

	useLayoutEffect(() => {
		setTimeout(() => {
			setIsReady(true);
			setTimeout(() => {
				setIsFormVisible(true);
				setCollapsed(false);
			}, 400);
		}, 500);
	}, []);

	const [Collapsed, setCollapsed] = useState(true);

	const onCollapse = () => {
		setCollapsed(!Collapsed);
	};

	const DownloadModalProps = {
		visible: IsDownloadModalVisible,
		setVisible: setIsDownloadModalVisible,
	};

	const showDownloadModal = () => {
		setIsDownloadModalVisible(true);
	};

	const downloadCSV = async () => {
		showDownloadModal();
	};

	return user.isLoggedIn ? (
		<>
			<div className={!IsReady ? 'bg' : 'bg cssblur'}> </div>
			<Layout style={{ height: '100vh', background: 'transparent' }} className={!IsFormVisible ? 'main-layout' : 'main-layout visible'}>
				{/* Sidebar */}
				<Sider collapsed={Collapsed} onCollapse={onCollapse} collapsedWidth={0} style={{ zIndex: 4 }}>
					<div className='logo' />

					<Sidebar user={user} />

					<div className='sidebar-bottom-fix'>
						<Divider style={{ marginTop: 0, borderTop: '1px solid #efefef' }} />

						<UserInfo user={user} company={company} store={store}></UserInfo>
					</div>
				</Sider>

				{/* Main Content */}
				<Layout className={!IsReady ? 'site-layout' : 'site-layout whitey'} style={{ minHeight: '100vh', overflow: 'scroll' }}>
					<Content style={{ margin: '0 16px', background: 'transparent' }}>
						<Row className='site-layout-background'>
							<Col flex={'auto'}>
								{' '}
								{/* <Component {...props} /> */}
								{props.children}
							</Col>
						</Row>
					</Content>

					<Footer
						style={{
							textAlign: 'center',
							color: '#ffffff',
							background: '#4d3d32',
							padding: '5px 50px',
						}}
					>
						<small>
							{' '}
							{t('Kasenblatt © ')} {new Date().getFullYear()} {t(' by 2B Visions AG')}
						</small>
					</Footer>
				</Layout>
				{/* Background color of page title */}
				<Affix
					className={'title'}
					offsetTop={0}
					style={{ width: '100%', height: '80px', position: 'absolute', zIndex: 3 }}
					onChange={(affixed) => setIsAffixed(affixed)}
				>
					<Row
						style={{
							width: '100%',
							height: '80px',
							backgroundColor: isAffixed ? 'rgba(102, 84, 70, 0.9)' : 'rgba(255,255,255,0.0)',
							boxShadow: isAffixed ? '0 2px 12px rgb(0 0 0 / 60%)' : '0 2px 12px rgb(0 0 0 / 0%)',
							transition: 'all ease-in 150ms',
						}}
					></Row>
				</Affix>
			</Layout>
		</>
	) : (
		props.children
	);
};

export default inject('store')(observer(MainLayout));
