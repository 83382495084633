import { types, flow, cast, getRoot } from "mobx-state-tree";

import BillingAccountValuesModel from "./BillingAccountValuesModel";
import AccountsModel from "../Accounts/AccountsModel";
import OrderDetailsModel from "../Accounts/OrderDetailsModel";
import DifferenceModel from "./BillingDifference";
// import ValuesModel from './ValuesModel';

const ValuesModel = types.model("values", {
  qty: types.optional(types.maybeNull(types.number), 0),
  amount: types.optional(types.maybeNull(types.number), 0),
});

const BillingAccountsModel = types
  .model("BillingAccountsModel", {
    id: types.optional(types.maybeNull(types.number), 0),
    billing_id: types.optional(types.maybeNull(types.number), 0),
    account_id: types.optional(types.maybeNull(types.number), 0),
    name: types.optional(types.maybeNull(types.string), ""),
    groupName: types.optional(types.maybeNull(types.string), ""),
    groupId: types.optional(types.maybeNull(types.number), 0),
    accId: types.optional(types.maybeNull(types.number), 0),
    comment: types.optional(types.maybeNull(types.string), ""),
    isStatic: types.optional(types.maybeNull(types.number), 0),
    isTitle: types.optional(types.maybeNull(types.number), 0),
    isChecked: types.optional(types.maybeNull(types.number), 0),
    Account: types.optional(types.maybeNull(AccountsModel), {}),
    Values: types.optional(types.maybeNull(types.array(BillingAccountValuesModel)), []),
    OrderDetails: types.optional(types.maybeNull(OrderDetailsModel), {}),
    qty: types.optional(types.maybeNull(types.number), 0),
    amount: types.optional(types.maybeNull(types.number), 0),
    payment_type_id: types.optional(types.maybeNull(types.number), 0),
    difference: types.maybeNull(DifferenceModel),
    total_employee: types.maybeNull(DifferenceModel),
    total: types.optional(types.maybeNull(ValuesModel), {}),
    Monday: types.optional(types.maybeNull(ValuesModel), {}),
    Tuesday: types.optional(types.maybeNull(ValuesModel), {}),
    Wednesday: types.optional(types.maybeNull(ValuesModel), {}),
    Thursday: types.optional(types.maybeNull(ValuesModel), {}),
    Friday: types.optional(types.maybeNull(ValuesModel), {}),
    Saturday: types.optional(types.maybeNull(ValuesModel), {}),
    Sunday: types.optional(types.maybeNull(ValuesModel), {}),
  })
  .views((self) => ({
    get account_name() {
      const accounts = getRoot(self).accounts.state;
      return accounts.find((e) => e.id === self.account_id)?.name;
    },

    getQty(id, column_id) {
      const Value = this.Values.find((e) => e.billing_account_id === id && e.billing_account_columns_id === column_id);
      return !!Value ? Value.qty : null;
    },

    getAmount(id, column_id) {
      const Value = this.Values.find((e) => e.billing_account_id === id && e.billing_account_columns_id === column_id);
      return !!Value ? Value.amount : null;
    },

    get key() {
      return self.id;
    },
  }));
export default BillingAccountsModel;
