import { types, flow, getParent, getRoot } from 'mobx-state-tree';
import LocalUnitsModel from '../LocalUnits/LocalUnitsModel';
import EmployeeBusinessUnitsModel from '../EmployeeManagement/EmployeeBusinessUnitsModel';
import AccountBusinessUnitsModel from '../Accounts/AccountBusinessUnitsModel';
import axios from 'axios';

const BusinessUnitsModel = types
	.model('BusinessUnitsModel', {
		id: types.optional(types.number, 0),
		local_unit_id: types.optional(types.number, 0),
		name: types.optional(types.string, ''),
		kostentelle: types.optional(types.maybeNull(types.number), 0),
		address: types.optional(types.string, ''),
		isActive: types.optional(types.number, 1),
		LocalUnit: types.optional(LocalUnitsModel(false), {}),
		EmployeeBusinessUnits: types.optional(EmployeeBusinessUnitsModel, {}),
		AccountBusinessUnits: types.optional(AccountBusinessUnitsModel, {}),
	})
	.views((self) => ({
		get key() {
			return self.id;
		},

		get disabled() {
			const business_units = getRoot(self).businessUnits.state;
			return !business_units.find((e) => e.id === self.id);
		},

		get EmployeeBusinessUnitIsActive() {
			return self.EmployeeBusinessUnits.isActive;
		},

		get unit_type_id() {
			return 1;
		},

		get getLengthOfBusinessUnit() {
			return getParent(self).toJSON().length;
		},
	}))
	.actions((self) => ({
		setName(name) {
			self.name = name;
		},

		setAddress(address) {
			self.address = address;
		},

		setIsActive(isActive) {
			self.isActive = isActive;
		},

		addBusinessUnit(obj) {
			self.businessUnits.push(obj);
		},

		UPDATE: flow(function* (id, values) {
			const { data } = yield axios.put(`/api/businessUnits/${id}`, values);
			self = Object.assign(self, data);
		}),

		DELETE: flow(function* (emp_business_unit_id, record) {
			const parent = getParent(getParent(self));
			yield axios.delete('/api/businessUnits/employeeBusinessUnit', { data: { ids: [emp_business_unit_id] } });
			parent.REMOVE_BUSINESS_UNIT(record);
		}),
	}));
export default BusinessUnitsModel;
