import { types, flow, cast } from 'mobx-state-tree';
import OtherSettingsModel from './OtherSettingsModel'
import axios from 'axios';


export default types
  .model('LocalUnitsState', {
    state: types.optional(types.array(OtherSettingsModel), []),
    loading: types.optional(types.boolean, false)
  })
  .views((self) => ({
    get tresoberstand() {
      return this.state.find(e => e.name === 'tresoberstand') || { value: 0 }
    }
  }))
  .actions((self) => ({
    FETCH_DATA: flow(function* (name = '') {
      self.loading = true;

      const { data } = yield axios.get(`/api/otherSettings/${name}`);
      self.state = cast(data);

      self.loading = false;
    }),
  }));
