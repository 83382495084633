import { types, flow, cast } from 'mobx-state-tree';
import axios from 'axios';
import GroupModel from './GroupModel';

export default types
  .model('GroupState', {
    state: types.optional(types.array(GroupModel), []),
    loading: types.optional(types.boolean, false)
  })
  .views((self) => ({
    get filters() {
      return this.state.map((e) => ({ text: e.name, value: e.id }));
    },
  }))
  .actions((self) => ({
    FETCH_DATA: flow(function* (isActive) {
      self.loading = true;

      const { data } = yield axios.get(`/api/groups/${isActive ? 'isActive': ''}`);
      self.state = cast(data);

      self.loading = false;
    }),

    UPDATE: flow(function* (values) {
      const payload = values.map((e, i) => ({ ...e, order_no: i }));
      self.state = cast(
        values.map((e, i) => {
          e.SET('order_no', i);
          return e;
        })
      );
      yield axios.put(`/api/groups/`, payload);
    }),

    ADD: flow(function* (values) {
      const { data } = yield axios.post('/api/groups/', values);
      self.state.push(data);
    }),
  }))
  .views((self) => ({}));
