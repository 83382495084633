import { types, flow, cast } from "mobx-state-tree";

const DayListModel = types
  .model("DayListModel", {
    date: types.optional(types.maybeNull(types.string), ""),
    description: types.optional(types.maybeNull(types.string), ""),
    sortNum: types.optional(types.maybeNull(types.number), 0),
    grund: types.optional(types.maybeNull(types.string), ""),
    grund_id: types.optional(types.maybeNull(types.number), 0),
    value: types.optional(types.maybeNull(types.number), 0),
    essen: types.optional(types.maybeNull(types.number), 0),
    total_worked_time: types.optional(types.maybeNull(types.number), 0),
    ruhetag: types.optional(types.boolean, false),
    pause_time: types.optional(types.maybeNull(types.string), ""),
    von_1: types.optional(types.maybeNull(types.string), ""),
    bis_1: types.optional(types.maybeNull(types.string), ""),
    von_2: types.optional(types.maybeNull(types.string), ""),
    bis_2: types.optional(types.maybeNull(types.string), ""),
    von_3: types.optional(types.maybeNull(types.string), ""),
    bis_3: types.optional(types.maybeNull(types.string), ""),
    von_4: types.optional(types.maybeNull(types.string), ""),
    bis_4: types.optional(types.maybeNull(types.string), ""),
    von_5: types.optional(types.maybeNull(types.string), ""),
    bis_5: types.optional(types.maybeNull(types.string), ""),
    von_6: types.optional(types.maybeNull(types.string), ""),
    bis_6: types.optional(types.maybeNull(types.string), ""),
    daily_timelog: types.optional(types.maybeNull(types.string), ""),
    daily_nighttimelog: types.optional(types.maybeNull(types.string), ""),
    daily_nighttimelog_display: types.optional(types.maybeNull(types.string), ""),
    ten_percent: types.optional(types.maybeNull(types.string), ""),
    weekly_timelog: types.optional(types.maybeNull(types.string), ""),
    uberzeit_geleis: types.optional(types.maybeNull(types.string), ""),
    uberzeit_komp: types.optional(types.maybeNull(types.string), ""),
    isGray: types.optional(types.boolean, false),
    personel_category: types.optional(types.maybeNull(types.string), ""),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default DayListModel;
