import { types, flow, getParent, getRoot } from "mobx-state-tree";
import BusinessUnitsModel from "../BusinessUnits/BusinessUnitsModel";
import BillingAccountColumnsModel from "./BillingAccountColumnsModel";
import BillingAccountsModel from "./BillingAccountsModel";
import BillingCommentsModel from "./BillingCommentsModel";
import axios from "axios";
import UserTypesModel from "../UserManagement/UserTypesModel";
import ApiWrapper from "../../utils/ApiWrapper";
import { toJS } from "mobx";
import BillingsAccountDaynameModel from "./BillingsAccountDaynameModel";
import CalculationModel from "./CalculationModel";
import moment from "moment";

export default types
  .model("BillingsModel", {
    id: types.optional(types.number, 0),
    business_unit_id: types.optional(types.number, 0),
    status: types.optional(types.number, 0),
    date: types.optional(types.string, ""),
    Comments: types.optional(types.maybeNull(types.array(BillingCommentsModel)), []),
    BillingAccounts: types.optional(types.maybeNull(types.array(BillingAccountsModel)), []),
    BillingStaticAccounts: types.optional(types.maybeNull(types.array(BillingAccountsModel)), []),
    BusinessUnit: types.optional(types.maybeNull(BusinessUnitsModel), {}),
    Columns: types.optional(types.maybeNull(types.array(BillingAccountColumnsModel)), []),
    zahlungskonten: types.optional(types.maybeNull(types.array(BillingAccountsModel)), []),
    umsatzkonten: types.optional(types.maybeNull(types.array(BillingAccountsModel)), []),
    eihnnamen: types.optional(types.maybeNull(types.array(BillingAccountsModel)), []),
    einnahmen: types.optional(types.maybeNull(types.array(BillingsAccountDaynameModel)), []),
    static_accounts: types.optional(types.array(BillingAccountsModel), []),
    user_type_id: types.optional(types.maybeNull(types.number), 0),
    UserType: types.optional(types.maybeNull(UserTypesModel), {}),
    previousSaldo: types.optional(types.maybeNull(types.number), 0),
    calculation: types.optional(types.maybeNull(types.array(CalculationModel)), []),
  })
  .views((self) => {
    const apiWrapper = ApiWrapper(getParent(self).loading_businessUnit);

    return {
      get key() {
        return self.id;
      },

      calculations(isBusinessUnit = false) {
        let anzahl_gast = [],
          getranke_temp = [],
          kafi_temp = [],
          wine_temp = [],
          food_temp = [],
          getranke_account_ids = [],
          kafi_account_ids = [],
          wine_account_ids = [],
          food_account_ids = [],
          total_umsatzkonten = {},
          getranke = {},
          wine = {},
          getranke_emp = {},
          wine_emp = {},
          kafi = {},
          food = {},
          SalesAccounts = [];

        // Default Locale Option
        const locale_options = ["de-CH", { minimumFractionDigits: 2, maximumFractionDigits: 2 }];

        // Sub Functions
        const getSum = (accounts, business_unit_id, isTotalEmployee = false) =>
          accounts.reduce(
            (a, b) => {
              // if (isTotalEmployee) {
              // 	a = a?.total_employee;
              // 	b = b?.total_employee;
              // }

              // 2021-06-24 (Change | Calculation should be per business Unit for local unit calculation)
              // const qty = (a.qty || 0) + (b.qty || 0);
              // const amount = (a.amount || 0) + (b.amount || 0);

              b = b.Values.find((e) => e.business_unit_id === business_unit_id && e.billing_account_columns_id === 1) || { qty: 0, amount: 0 };

              const qty = (a.qty || 0) + (b.qty || 0);
              const amount = (a.amount || 0) + (b.amount || 0);

              return (a = { qty, amount });
            },
            { qty: 0, amount: 0 }
          );

        if (isBusinessUnit) {
          SalesAccounts = this.filterAccountType(this.BillingAccounts, 1, null, true);
          const group_sel = (id) => SalesAccounts.filter((account) => id === (account.OrderDetails ? account.OrderDetails.Group.id : null)) || [];
          const account_sel = (id) => SalesAccounts.filter((account) => id === account.account_id);

          // Get values of anzahl gast -> always on first index
          anzahl_gast = this.BillingStaticAccounts.toJSON().length !== 0 ? this.BillingStaticAccounts.toJSON()[0] : { Values: [], isStatic: 1 };

          // Get ids of group accounts separately
          getranke_temp = group_sel(1);
          kafi_temp = account_sel(1);
          wine_temp = account_sel(4);
          food_temp = group_sel(2);

          // Get sum of groups account values
          total_umsatzkonten = (column_id) => (SalesAccounts.length !== 0 ? this.getSumOfAccount(SalesAccounts, column_id) : { qty: 0, amount: 0 });
          // total_umsatzkonten = SalesAccounts.length !== 0 ? this.getSumOfAccount(SalesAccounts, 1) : { qty: 0, amount: 0 };
          // getranke = this.getSumOfAccount(getranke_temp, 1);
          // wine = this.getSumOfAccount(wine_temp, 1);
          // kafi = this.getSumOfAccount(kafi_temp, 1);
          // food = this.getSumOfAccount(food_temp, 1);

          // Delete name property on anzahl gast variable to avoid overwrite
          delete anzahl_gast.name;
        } else {
          SalesAccounts = getParent(getParent(self));
          const group_sel = (id) => SalesAccounts.groups.state.find((group) => group.id === id) || { Accounts: [] };

          anzahl_gast =
            this.static_accounts.length !== 0
              ? this.static_accounts[0].Values.map((e) => ({
                  id: e.business_unit_id,
                  [`business_unit_id_${e.business_unit_id}`]: e.qty || 0,
                }))
              : [];

          // Get ids of group accounts separately
          getranke_account_ids = group_sel(1).Accounts.map((e) => e.id);
          kafi_account_ids = group_sel(1)
            .Accounts.map((e) => e.id)
            .filter((e) => e === 1);
          wine_account_ids = group_sel(1)
            .Accounts.map((e) => e.id)
            .filter((e) => e === 4);
          food_account_ids = group_sel(2).Accounts.map((e) => e.id);
          // console.log(getranke_account_ids);

          const filterUmsatzkonten = (ids) => this.umsatzkonten.filter((account) => ids.find((e) => e === account.account_id));

          // Get sum of groups account values
          total_umsatzkonten = (business_unit_id) =>
            this.umsatzkonten.length !== 0
              ? this.umsatzkonten
                  .filter((e) => e.id > 0)
                  .reduce(
                    (a, b, i) => {
                      // console.log(a.amount);
                      b = b.Values.find((e) => e.business_unit_id === business_unit_id && e.billing_account_columns_id === 1) || {
                        qty: 0,
                        amount: 0,
                      };
                      // if (business_unit_id === 1) console.log(business_unit_id, ' | ', a.amount);
                      return (a = { qty: (a.qty || 0) + b.qty, amount: (a.amount || 0) + b.amount });
                    },
                    { qty: 0, amount: 0 }
                  )
              : { qty: 0, amount: 0 };
          // getranke_emp = getSum(filterUmsatzkonten(kafi_account_ids, true));
          // wine_emp = getSum(filterUmsatzkonten(wine_account_ids, true));
          getranke = (business_unit_id) => getSum(filterUmsatzkonten(getranke_account_ids), business_unit_id);
          wine = (business_unit_id) => getSum(filterUmsatzkonten(wine_account_ids), business_unit_id);
          kafi = (business_unit_id) => getSum(filterUmsatzkonten(kafi_account_ids), business_unit_id);
          food = (business_unit_id) => getSum(filterUmsatzkonten(food_account_ids), business_unit_id);
        }

        let anzahlGastObj = null;
        if (isBusinessUnit) {
          if (anzahl_gast) {
            if (anzahl_gast.Values && anzahl_gast.Values.length > 0) {
              anzahlGastObj = JSON.parse(JSON.stringify(anzahl_gast));
              const anzhalTotal = anzahlGastObj.Values.reduce((acc, obj) => {
                if (obj.billing_account_columns_id !== 1) {
                  acc += obj.qty;
                }

                return acc;
              }, 0);
              //anzahlGastObj.Values

              const totalIndex = anzahlGastObj.Values.findIndex((a) => a.billing_account_columns_id === 1);

              if (totalIndex > -1) {
                anzahlGastObj.Values[totalIndex].qty = anzhalTotal;
              } else {
                let grandTotal = anzahlGastObj.Values.reduce((total, current) => {
                  return total + current.qty;
                }, 0);

                anzahlGastObj.Values.unshift({
                  amount: 0,
                  billing_account_columns_id: 1,
                  qty: grandTotal,
                });
              }
            }
          }
        } else {
          // anzahlGastObj = anzahl_gast;
        }

        if (!anzahlGastObj) {
          anzahlGastObj = anzahl_gast;
        }
        //console.log("export", !isBusinessUnit ? anzahlGastObj.reduce((a, b) => (a = { ...a, ...b }), {}) : anzahlGastObj);
        return [
          {
            key: "Anzahl Gäste",
            name: "Anzahl Gäste",
            ...(!isBusinessUnit ? anzahlGastObj.reduce((a, b) => (a = { ...a, ...b }), {}) : anzahlGastObj),
          },
          {
            //anzahl_gast
            key: "Getranke/Person (ohne Kafi)",
            name: "Getränke/Person (ohne Kaffee)",
            ...(!isBusinessUnit
              ? anzahlGastObj.reduce(
                  (a, b) =>
                    (a = {
                      ...a,
                      [`business_unit_id_${b.id}`]: (b[`business_unit_id_${b.id}`] !== 0
                        ? (getranke(b.id).qty - kafi(b.id).qty) / b[`business_unit_id_${b.id}`]
                        : 0
                      ).toFixed(2),
                    }),
                  {}
                )
              : {
                  key: -5,
                  Values: anzahlGastObj.Values.map((e) => ({
                    ...e,

                    qty: (e.qty !== 0
                      ? (this.getSumOfAccount(getranke_temp, e.billing_account_columns_id).qty -
                          this.getSumOfAccount(kafi_temp, e.billing_account_columns_id).qty) /
                        e.qty
                      : 0
                    ).toFixed(2),
                  })),
                }),
          },
          //here
          {
            key: "Durschnitt CHF/Gast",
            name: "Durchschnitt CHF / Gast",
            ...(!isBusinessUnit
              ? anzahlGastObj.reduce(
                  (a, b) =>
                    (a = {
                      ...a,
                      [`business_unit_id_${b.id}`]: (b[`business_unit_id_${b.id}`] !== 0
                        ? total_umsatzkonten(b.id).amount / b[`business_unit_id_${b.id}`]
                        : 0
                      ).toLocaleString(...locale_options),
                    }),
                  {}
                )
              : {
                  key: -5,
                  Values: anzahlGastObj.Values.map((e) => ({
                    ...e,
                    qty: (e.qty !== 0 ? total_umsatzkonten(e.billing_account_columns_id).amount / e.qty : 0).toLocaleString(...locale_options),
                  })),
                }),
          },
          {
            key: "Getranke CHF/Gast",
            name: "Getränke/Gast",
            ...(!isBusinessUnit
              ? anzahlGastObj.reduce(
                  (a, b) =>
                    (a = {
                      ...a,
                      [`business_unit_id_${b.id}`]: (b[`business_unit_id_${b.id}`] !== 0
                        ? getranke(b.id).amount / b[`business_unit_id_${b.id}`]
                        : 0
                      ).toLocaleString(...locale_options),
                    }),
                  {}
                )
              : {
                  key: -5,
                  Values: anzahlGastObj.Values.map((e) => ({
                    ...e,
                    qty: (e.qty !== 0 ? this.getSumOfAccount(getranke_temp, e.billing_account_columns_id).amount / e.qty : 0).toLocaleString(...locale_options),
                  })),
                }),
          },
          {
            key: "Food CHF/ Gast",
            name: "Essen CHF / Gast",
            ...(!isBusinessUnit
              ? anzahlGastObj.reduce(
                  (a, b) =>
                    (a = {
                      ...a,
                      [`business_unit_id_${b.id}`]: (b[`business_unit_id_${b.id}`] !== 0
                        ? food(b.id).amount / b[`business_unit_id_${b.id}`]
                        : 0
                      ).toLocaleString(...locale_options),
                    }),
                  {}
                )
              : {
                  key: -5,
                  Values: anzahlGastObj.Values.map((e) => ({
                    ...e,
                    qty: (e.qty !== 0 ? this.getSumOfAccount(food_temp, e.billing_account_columns_id).amount / e.qty : 0).toLocaleString(...locale_options),
                  })),
                }),
          },
          {
            key: "Anteil Food % /CHF",
            name: "Anteil Essen % / CHF",
            ...(!isBusinessUnit
              ? anzahlGastObj.reduce(
                  (a, b) =>
                    (a = {
                      isPercent: true,
                      ...a,
                      [`business_unit_id_${b.id}`]: ((food(b.id).amount / total_umsatzkonten(b.id).amount) * 100 || 0).toLocaleString(...locale_options) + "%",
                    }),
                  {}
                )
              : {
                  key: -5,
                  isPercent: true,
                  Values: anzahlGastObj.Values.map((e) => ({
                    ...e,
                    qty:
                      (
                        (this.getSumOfAccount(food_temp, e.billing_account_columns_id).amount / total_umsatzkonten(e.billing_account_columns_id).amount) *
                          100 || 0
                      ).toLocaleString(...locale_options) + "%",
                  })),
                }),
          },
          {
            key: "Kafiquote (>Durschnitt)",
            name: "Kaffeequote (> Durchschnitt)",
            ...(!isBusinessUnit
              ? anzahlGastObj.reduce(
                  (a, b) =>
                    (a = {
                      isPercent: true,
                      ...a,
                      [`business_unit_id_${b.id}`]: ((kafi(b.id).amount / total_umsatzkonten(b.id).amount) * 100 || 0).toLocaleString(...locale_options) + "%",
                    }),
                  {}
                )
              : {
                  key: -5,
                  isPercent: true,
                  Values: anzahlGastObj.Values.map((e) => ({
                    ...e,
                    qty:
                      (
                        (this.getSumOfAccount(kafi_temp, e.billing_account_columns_id).amount / total_umsatzkonten(e.billing_account_columns_id).amount) *
                          100 || 0
                      ).toLocaleString(...locale_options) + "%",
                  })),
                }),
          },
          {
            key: "Weinquote (>Durschnitt)",
            name: "Weinquote (> Durchschnitt)",
            ...(!isBusinessUnit
              ? anzahlGastObj.reduce(
                  (a, b) =>
                    (a = {
                      isPercent: true,
                      ...a,
                      [`business_unit_id_${b.id}`]: ((wine(b.id).amount / total_umsatzkonten(b.id).amount) * 100 || 0).toLocaleString(...locale_options) + "%",
                    }),
                  {}
                )
              : {
                  key: -5,
                  isPercent: true,
                  Values: anzahlGastObj.Values.map((e) => ({
                    ...e,
                    qty:
                      (
                        (this.getSumOfAccount(wine_temp, e.billing_account_columns_id).amount / total_umsatzkonten(e.billing_account_columns_id).amount) *
                          100 || 0
                      ).toLocaleString(...locale_options) + "%",
                  })),
                }),
          },
        ];
      },

      get calculationWeekly() {
        let GROUP_IDS = {
          Getranke: 1,
          Essen: 2,
        };

        let ACCOUNT_IDS = {
          Kaffe: 1,
          Mineral: 2,
          Bier: 3,
          Wein: 4,
        };

        const calculateGetrankeGroup = (dayName) => {
          let getrankeGroup = self.umsatzkonten.filter((c) => c.groupName === "Getränke" && c.name !== "Total" && c.name !== "Getränke" && c.name !== "Kaffee");
          let total = getrankeGroup.reduce((total, current) => {
            return total + current[dayName].qty;
          }, 0);

          return total;
        };

        const getAnzahlGast = (dayName) => {
          //check here for kaffee
          const getDaily = self.calculation.filter((ele) => {
            const dateEn = ele.date.split(".").join("-");
            moment.locale("en");
            return moment(dateEn, "DD-MM-YYYY").format("dddd") === dayName;
          });

          let total = 0;
          getDaily.forEach((item) => {
            item.BillingStaticAccounts.forEach((bsa) => {
              bsa.Values.forEach((value) => {
                if (value.billing_account_columns_id !== 1) {
                  total += value.qty;
                }
              });
            });
          });

          return total;
        };

        let getDurchschnitt = (dayName) => {
          let totalData = self.umsatzkonten.filter((c) => c.name === "Total");
          let total = totalData.reduce((total, current) => {
            return total + current[dayName].amount;
          }, 0);
          return total;
        };

        const getTotalAmount = (dayName, groupId) => {
          let getrankeGroup = self.umsatzkonten.filter((c) => c.groupId === groupId && c.name === "Total");

          let total = getrankeGroup.reduce((total, current) => {
            return total + current[dayName].amount;
          }, 0);
          return total;
        };

        const getTotalItem = (dayName, accId) => {
          let itemGroup = self.umsatzkonten.filter((c) => c.accId === accId && c.name !== "Total");

          let total = itemGroup.reduce((total, current) => {
            return total + parseFloat(current[dayName].amount);
          }, 0);
          return total;
        };

        const isNanToZero = (value) => {
          if (isNaN(value)) {
            return 0;
          } else if (!isFinite(value)) {
            return 0;
          }
          return value;
        };

        const getTotalAnzahlGaste =
          getAnzahlGast("Monday") +
          getAnzahlGast("Tuesday") +
          getAnzahlGast("Wednesday") +
          getAnzahlGast("Thursday") +
          getAnzahlGast("Friday") +
          getAnzahlGast("Saturday") +
          getAnzahlGast("Sunday");

        const calculateTotalGroup = (groupId, type) => {
          let getrankeGroup = self.umsatzkonten.filter((c) => c.groupId === groupId && c.name === "Total");
          let total = getrankeGroup.reduce((total, current) => {
            return total + current.total[type];
          }, 0);
          return total;
        };

        const calculateTotalGetrankeCategories = (type, accId) => {
          let getrankeGroup = self.umsatzkonten.filter((c) => c.accId === accId && c.name !== "Total");

          let totalKaffee = getrankeGroup.reduce((total, current) => {
            return total + current.total[type];
          }, 0);
          return totalKaffee;
        };
        // const calculateTotalGetrankeCategories = (type, name) => {
        //   console.log(self.umsatzkonten.toJSON(), "getrankeGroup");
        //   let getrankeGroup = self.umsatzkonten.filter((c) => c.groupName === "Getränke" && c.name === name && c.name !== "Getränke");
        //   let totalKaffee = getrankeGroup.reduce((total, current) => {
        //     return total + current.total[type];
        //   }, 0);
        //   return totalKaffee;
        // };

        const getTotal_umzats = () => {
          let totalData = self.umsatzkonten.filter((c) => c.name === "Total");
          let total = totalData.reduce((total, current) => {
            return total + current.total.amount;
          }, 0);
          return total;
        };

        return [
          {
            key: "Anzahl Gäste",
            name: "Anzahl Gast",
            amount: getTotalAnzahlGaste,
            Monday: { amount: getAnzahlGast("Monday") },
            Tuesday: { amount: getAnzahlGast("Tuesday") },
            Wednesday: { amount: getAnzahlGast("Wednesday") },
            Thursday: { amount: getAnzahlGast("Thursday") },
            Friday: { amount: getAnzahlGast("Friday") },
            Saturday: { amount: getAnzahlGast("Saturday") },
            Sunday: { amount: getAnzahlGast("Sunday") },
          },
          {
            key: "Getranke/Person (ohne Kafi)",
            name: "Getränke/Person (ohne Kaffee)",
            amount: isNanToZero(
              ((calculateTotalGroup(GROUP_IDS.Getranke, "qty") - calculateTotalGetrankeCategories("qty", ACCOUNT_IDS.Kaffe)) / getTotalAnzahlGaste).toFixed(2)
            ),
            Monday: { amount: isNanToZero((calculateGetrankeGroup("Monday") / getAnzahlGast("Monday")).toFixed(2)) },
            Tuesday: { amount: isNanToZero((calculateGetrankeGroup("Tuesday") / getAnzahlGast("Tuesday")).toFixed(2)) },
            Wednesday: { amount: isNanToZero((calculateGetrankeGroup("Wednesday") / getAnzahlGast("Wednesday")).toFixed(2)) },
            Thursday: { amount: isNanToZero((calculateGetrankeGroup("Thursday") / getAnzahlGast("Thursday")).toFixed(2)) },
            Friday: { amount: isNanToZero((calculateGetrankeGroup("Friday") / getAnzahlGast("Friday")).toFixed(2)) },
            Saturday: { amount: isNanToZero((calculateGetrankeGroup("Saturday") / getAnzahlGast("Saturday")).toFixed(2)) },
            Sunday: { amount: isNanToZero((calculateGetrankeGroup("Sunday") / getAnzahlGast("Sunday")).toFixed(2)) },
          },
          {
            key: "Durschnitt CHF/Gast",
            name: "Durchschnitt CHF / Gast",
            amount: isNanToZero((getTotal_umzats() / getTotalAnzahlGaste).toFixed(2)),
            Monday: { amount: isNanToZero((getDurchschnitt("Monday") / getAnzahlGast("Monday")).toFixed(2)) },
            Tuesday: { amount: isNanToZero((getDurchschnitt("Tuesday") / getAnzahlGast("Tuesday")).toFixed(2)) },
            Wednesday: { amount: isNanToZero((getDurchschnitt("Wednesday") / getAnzahlGast("Wednesday")).toFixed(2)) },
            Thursday: { amount: isNanToZero((getDurchschnitt("Thursday") / getAnzahlGast("Thursday")).toFixed(2)) },
            Friday: { amount: isNanToZero((getDurchschnitt("Friday") / getAnzahlGast("Friday")).toFixed(2)) },
            Saturday: { amount: isNanToZero((getDurchschnitt("Saturday") / getAnzahlGast("Saturday")).toFixed(2)) },
            Sunday: { amount: isNanToZero((getDurchschnitt("Sunday") / getAnzahlGast("Sunday")).toFixed(2)) },
          },
          {
            key: "Getranke CHF/Gast",
            name: "Getränke/Gast",
            amount: isNanToZero((calculateTotalGroup(GROUP_IDS.Getranke, "amount") / getTotalAnzahlGaste).toFixed(2)),
            Monday: { amount: isNanToZero((getTotalAmount("Monday", GROUP_IDS.Getranke) / getAnzahlGast("Monday")).toFixed(2)) },
            Tuesday: { amount: isNanToZero((getTotalAmount("Tuesday", GROUP_IDS.Getranke) / getAnzahlGast("Tuesday")).toFixed(2)) },
            Wednesday: { amount: isNanToZero((getTotalAmount("Wednesday", GROUP_IDS.Getranke) / getAnzahlGast("Wednesday")).toFixed(2)) },
            Thursday: { amount: isNanToZero((getTotalAmount("Thursday", GROUP_IDS.Getranke) / getAnzahlGast("Thursday")).toFixed(2)) },
            Friday: { amount: isNanToZero((getTotalAmount("Friday", GROUP_IDS.Getranke) / getAnzahlGast("Friday")).toFixed(2)) },
            Saturday: { amount: isNanToZero((getTotalAmount("Saturday", GROUP_IDS.Getranke) / getAnzahlGast("Saturday")).toFixed(2)) },
            Sunday: { amount: isNanToZero((getTotalAmount("Sunday", GROUP_IDS.Getranke) / getAnzahlGast("Sunday")).toFixed(2)) },
          },
          {
            key: "Food CHF/ Gast",
            name: "Essen CHF / Gast",
            amount: isNanToZero((calculateTotalGroup(GROUP_IDS.Essen, "amount") / getTotalAnzahlGaste).toFixed(2)),
            Monday: { amount: isNanToZero((getTotalAmount("Monday", GROUP_IDS.Essen) / getAnzahlGast("Monday")).toFixed(2)) },
            Tuesday: { amount: isNanToZero((getTotalAmount("Tuesday", GROUP_IDS.Essen) / getAnzahlGast("Tuesday")).toFixed(2)) },
            Wednesday: { amount: isNanToZero((getTotalAmount("Wednesday", GROUP_IDS.Essen) / getAnzahlGast("Wednesday")).toFixed(2)) },
            Thursday: { amount: isNanToZero((getTotalAmount("Thursday", GROUP_IDS.Essen) / getAnzahlGast("Thursday")).toFixed(2)) },
            Friday: { amount: isNanToZero((getTotalAmount("Friday", GROUP_IDS.Essen) / getAnzahlGast("Friday")).toFixed(2)) },
            Saturday: { amount: isNanToZero((getTotalAmount("Saturday", GROUP_IDS.Essen) / getAnzahlGast("Saturday")).toFixed(2)) },
            Sunday: { amount: isNanToZero((getTotalAmount("Sunday", GROUP_IDS.Essen) / getAnzahlGast("Sunday")).toFixed(2)) },
          },
          {
            key: "Anteil Food % /CHF",
            name: "Anteil Essen % / CHF",
            amount: `${isNanToZero((calculateTotalGroup(GROUP_IDS.Essen, "amount") / getTotal_umzats()) * 100).toFixed(2)}%`,
            Monday: { amount: `${isNanToZero((getTotalAmount("Monday", GROUP_IDS.Essen) / getDurchschnitt("Monday")) * 100).toFixed(2)}%` },
            Tuesday: { amount: `${isNanToZero((getTotalAmount("Tuesday", GROUP_IDS.Essen) / getDurchschnitt("Tuesday")) * 100).toFixed(2)}%` },
            Wednesday: { amount: `${isNanToZero((getTotalAmount("Wednesday", GROUP_IDS.Essen) / getDurchschnitt("Wednesday")) * 100).toFixed(2)}%` },
            Thursday: { amount: `${isNanToZero((getTotalAmount("Thursday", GROUP_IDS.Essen) / getDurchschnitt("Thursday")) * 100).toFixed(2)}%` },
            Friday: { amount: `${isNanToZero((getTotalAmount("Friday", GROUP_IDS.Essen) / getDurchschnitt("Friday")) * 100).toFixed(2)}%` },
            Saturday: { amount: `${isNanToZero((getTotalAmount("Saturday", GROUP_IDS.Essen) / getDurchschnitt("Saturday")) * 100).toFixed(2)}%` },
            Sunday: { amount: `${isNanToZero((getTotalAmount("Sunday", GROUP_IDS.Essen) / getDurchschnitt("Sunday")) * 100).toFixed(2)}%` },
          },
          {
            key: "Kafiquote (>Durschnitt)",
            name: "Kaffeequote (> Durchschnitt)",
            amount: `${isNanToZero((calculateTotalGetrankeCategories("amount", ACCOUNT_IDS.Kaffe) / getTotal_umzats()) * 100).toFixed(2)}%`,
            Monday: { amount: `${isNanToZero((getTotalItem("Monday", ACCOUNT_IDS.Kaffe) / getDurchschnitt("Monday")) * 100).toFixed(2)}%` },
            Tuesday: { amount: `${isNanToZero((getTotalItem("Tuesday", ACCOUNT_IDS.Kaffe, true) / getDurchschnitt("Tuesday")) * 100).toFixed(2)}%` },
            Wednesday: { amount: `${isNanToZero((getTotalItem("Wednesday", ACCOUNT_IDS.Kaffe) / getDurchschnitt("Wednesday")) * 100).toFixed(2)}%` },
            Thursday: { amount: `${isNanToZero((getTotalItem("Thursday", ACCOUNT_IDS.Kaffe) / getDurchschnitt("Thursday")) * 100).toFixed(2)}%` },
            Friday: { amount: `${isNanToZero((getTotalItem("Friday", ACCOUNT_IDS.Kaffe) / getDurchschnitt("Friday")) * 100).toFixed(2)}%` },
            Saturday: { amount: `${isNanToZero((getTotalItem("Saturday", ACCOUNT_IDS.Kaffe) / getDurchschnitt("Saturday")) * 100).toFixed(2)}%` },
            Sunday: { amount: `${isNanToZero((getTotalItem("Sunday", ACCOUNT_IDS.Kaffe) / getDurchschnitt("Sunday")) * 100).toFixed(2)}%` },
          },

          {
            key: "Weinquote (>Durschnitt)",
            name: "Weinquote (> Durchschnitt)",
            amount: `${isNanToZero((calculateTotalGetrankeCategories("amount", ACCOUNT_IDS.Wein) / getTotal_umzats()) * 100).toFixed(2)}%`,
            Monday: { amount: `${isNanToZero((getTotalItem("Monday", ACCOUNT_IDS.Wein) / getDurchschnitt("Monday")) * 100).toFixed(2)}%` },
            Tuesday: { amount: `${isNanToZero((getTotalItem("Tuesday", ACCOUNT_IDS.Wein) / getDurchschnitt("Tuesday")) * 100).toFixed(2)}%` },
            Wednesday: { amount: `${isNanToZero((getTotalItem("Wednesday", ACCOUNT_IDS.Wein) / getDurchschnitt("Wednesday")) * 100).toFixed(2)}%` },
            Thursday: { amount: `${isNanToZero((getTotalItem("Thursday", ACCOUNT_IDS.Wein) / getDurchschnitt("Thursday")) * 100).toFixed(2)}%` },
            Friday: { amount: `${isNanToZero((getTotalItem("Friday", ACCOUNT_IDS.Wein) / getDurchschnitt("Friday")) * 100).toFixed(2)}%` },
            Saturday: { amount: `${isNanToZero((getTotalItem("Saturday", ACCOUNT_IDS.Wein) / getDurchschnitt("Saturday")) * 100).toFixed(2)}%` },
            Sunday: { amount: `${isNanToZero((getTotalItem("Sunday", ACCOUNT_IDS.Wein) / getDurchschnitt("Sunday")) * 100).toFixed(2)}%` },
          },
        ];
      },

      get employeeColumns() {
        return this.Columns.filter((e) => {
          if (["personal", "takeaway"].includes(e.type)) {
            return true;
          } else if ("employee" === e.type) {
            const employees = getRoot(self).employees.state;
            return employees.find((emp) => emp.id === e.employee_id);
          }
        });
      },

      get noEmployee() {
        return this.employeeColumns.length;
      },

      get noGerate() {
        return this.Columns.filter((e) => e.type === "gerate").length;
      },

      get noGuest() {
        return this.Columns.filter((e) => e.type === "guest").length;
      },

      get local_unit_name() {
        return self.BusinessUnit.LocalUnit.name;
      },

      get business_unit_name() {
        return self.BusinessUnit.name;
      },

      findColumnType(column_id) {
        return (this.Columns.find((e) => e.id === column_id) || { type: "" }).type;
      },

      addValues(Accounts) {
        return Accounts.map((e) => {
          const BillingAccount = this.BillingAccounts.find((s) => s.account_id === e.id) || { Values: [], isChecked: 0 };
          const grand_total_value = BillingAccount.Values.find((e) => e.billing_account_columns_id === 1) || { qty: 0, amount: 0 };
          const total_employee_value = BillingAccount.Values.filter((e) =>
            ["employee", "personal", "takeaway"].includes(this.findColumnType(e.billing_account_columns_id))
          ).reduce(
            (a, b) =>
              (a = {
                qty: (a.qty || 0) + (b.qty || 0),
                amount: (a.amount || 0) + ((this.findColumnType(b.billing_account_columns_id) === "personal" ? 0 : b.amount) || 0),
              }),
            {
              qty: 0,
              amount: 0,
            }
          );

          return {
            ...e,
            Values: BillingAccount.Values,
            isChecked: BillingAccount.isChecked,
            ang: "100%",
            difference: {
              qty: grand_total_value.qty - total_employee_value.qty,
              amount: grand_total_value.amount - total_employee_value.amount,
            },
          };
        });
      },

      get filtered_columns() {
        // Removed personal and takeaway because of feedback from Ma'am Andrea
        // Added takeaway because of feedback from Ma'am Andrea (2021-06-1)
        return this.Columns.filter((column) => ["employee", "takeaway"].includes(column.type)).concat([{ id: 1 }]);
      },

      filterBusinessUnit(Accounts, selectedBusinessUnit) {
        // Check if there are accounts that are filtered but has values saved from billings. (Cases most likely to be: account is removed on a business unit)
        const billing_account_ids = this.BillingAccounts.filter((e) => e.Values.toJSON().length !== 0).map((e) => e.account_id);

        return Accounts.filter(
          (e) =>
            (e.BusinessUnits && ((e.BusinessUnits.map((b) => b.id).includes(selectedBusinessUnit) && e.isActive) || billing_account_ids.includes(e.id))) ||
            e.key
        );
      },

      filterAccountType(Accounts, account_type_id, payment_type_id, isFromBilling) {
        return Accounts.filter((account) => {
          const acc = isFromBilling ? account.Account : account;
          return acc.account_type_id === account_type_id && (!!payment_type_id ? acc.payment_type_id === payment_type_id : true);
        }).sort((a, b) => a.order_no - b.order_no);
      },

      generateObjectTitle(values, Accounts) {
        let c = 0;
        let identify = false;

        return Accounts.length !== 0
          ? values
              .map((e) => {
                let additional = { Values: [], difference: { qty: 0, amount: 0 }, isStatic: 1 };
                if (e === "TOTAL") {
                  additional = {
                    Values: this.Columns.concat([{ id: 1 }]).map((e) => ({
                      billing_account_columns_id: e.id,
                      ...this.getSumOfAccount(Accounts, e.id),
                    })),
                    difference: Accounts.reduce(
                      (a, b) =>
                        (a = {
                          qty: (a.qty || 0) + (b.difference.qty || 0),
                          amount: (a.amount || 0) + (b.difference.amount || 0),
                        }),
                      { qty: 0, amount: 0 }
                    ),
                    isStatic: 1,
                  };
                } else {
                  additional = { ...additional, isTitle: 1 };
                }

                return { key: e !== "TOTAL" ? -4 : e, name: e, ...additional, groupType: values[0] };
              })
              .concat(Accounts)
          : [];
      },

      getSumOfAccount(BillingAccounts, column_id, defaultValue = { qty: 0, amount: 0 }) {
        // Loop the accounts and get the sum of inside the Values object
        const Value = BillingAccounts.reduce((a, b) => {
          // if (column_id !== 1) console.log(column_id, b.Values);
          const res =
            b.Values.find((value) => (Array.isArray(column_id) ? column_id.includes(value.type) : value.billing_account_columns_id === column_id)) ||
            defaultValue;

          const qty = (a.qty || 0) + res.qty;
          const amount = (a.amount || 0) + res.amount;
          return (a = { qty, amount });
        }, defaultValue);

        return Value;
      },

      sortByGroupOrder(Accounts) {
        const uncategorized_id = 999;
        const checkNoCategory = (OrderDetails, isGroup) => {
          if (isGroup) {
            return OrderDetails ? OrderDetails.Group.order_no : uncategorized_id;
          }

          return OrderDetails ? OrderDetails.order_no : uncategorized_id;
        };

        return Accounts.sort((a, b) => {
          // 1st priority ->  Account Category
          if (checkNoCategory(a.OrderDetails, true) < checkNoCategory(b.OrderDetails, true)) return -1;
          if (checkNoCategory(a.OrderDetails, true) > checkNoCategory(b.OrderDetails, true)) return 1;

          // 2nd priority sort -> Account Category -> accounts
          if (checkNoCategory(a.OrderDetails) < b.OrderDetails) return -1;
          if (checkNoCategory(a.OrderDetails) > b.OrderDetails) return 1;
        });
      },

      categorizeSalesByGroup(Accounts) {
        let temp_group_id = -1;

        const insertData = (a, b) => {
          if (!b.OrderDetails) {
            let found_account;
            if ((found_account = a.find((e) => e.name === "UNCATEGORIZED"))) {
              found_account.Accounts.push(b);
            } else {
              a.push({ name: "UNCATEGORIZED", Accounts: [b], isActive: 1 });
            }
            temp_group_id = null;
          } else if (temp_group_id !== b.OrderDetails.Group.id) {
            a.push({ name: b.OrderDetails.Group.name.toUpperCase(), Accounts: [b], isActive: b.OrderDetails.Group.isActive });
            temp_group_id = b.OrderDetails.Group.id;
          } else {
            a[a.length - 1].Accounts.push(b);
          }
        };

        const res = this.sortByGroupOrder(Accounts).reduce((a, b) => {
          try {
            insertData(a, b);
          } catch (err) {
            insertData(a, b);
          }
          return a;
        }, []);
        return res;
      },

      calculateChange(Accounts) {
        let grand_total_amount = -1;

        const find_grand_total = (e) => e.Values.find((e) => e.billing_account_columns_id === 1) || { amount: 0, qty: 0 };

        return Accounts.map((e) => {
          if (e.isTitle || e.key === -3) {
            return e;
          } else if (e.key === "TOTAL") {
            grand_total_amount = find_grand_total(e).amount || 0;
            const grand_total = find_grand_total(e);
            return { ...e, ang: "100%", amount: grand_total.amount || 0, qty: grand_total.qty || 0 };
          } else {
            const total_amount = find_grand_total(e).amount || 0;
            return {
              ...e,
              ang: ((total_amount / grand_total_amount || 0) * 100).toFixed(0) + "%",
            };
          }
        });
      },

      updateTotalAng(Accounts, accountType) {
        // Update 'ang' property of sales account, if values under order is not null || 0, set to 100%, otherwise 0%
        const temp = [...Accounts].reverse();
        let id = -1;
        let hasValues = false;
        temp.forEach((e, i) => {
          if (e.id) {
            const temp_id = accountType === "sales" ? e.OrderDetails?.id || null : e.PaymentType.id;
            if (id !== temp_id) {
              id = temp_id;
              hasValues = false;
            }

            if (e.ang !== "0%") {
              hasValues = true;
            }
          } else if (e.key === "TOTAL") {
            Accounts[Accounts.length - 1 - i].ang = hasValues ? "100%" : "0%";
          } else if (e.key === -4) {
            hasValues = false;
          }
        });

        return Accounts;
      },

      filterInactiveGroups(Groups) {
        // Filter group but show only recorded values.
        return Groups.map((group) => ({
          ...group,
          Accounts: group.isActive ? group.Accounts : group.Accounts.filter((account) => account.Values.length !== 0),
        })).filter((group) => group.Accounts.length !== 0);
      },

      sales: apiWrapper(function (selectedBusinessUnit, isSalesTable) {
        // Temp data (array object)
        const result_temp = self.filterInactiveGroups(
          self.categorizeSalesByGroup(self.addValues(self.filterBusinessUnit(self.filterAccountType(getRoot(self).accounts.state, 1), selectedBusinessUnit)))
        );

        // Data
        const result = self.calculateChange(result_temp.reduce((a, b) => a.concat(self.generateObjectTitle([b.name, "TOTAL"], b.Accounts)), []));

        // Update 'ang' property of sales account, if values under order is not null || 0, set to 100%, otherwise 0%
        self.updateTotalAng(result, "sales");

        if (result.length !== 0) {
          const _sales = JSON.parse(JSON.stringify(result));

          const salesTotal = _sales.filter((sale) => sale.name === "TOTAL");

          let _alumniTotal = {
            Values: [],
            amount: 0,
            ang: "100%",
            difference: { qty: 0, amount: 0 },
            isStatic: 1,
            key: "TOTAL",
            name: "Grand Total",
            qty: 0,
          };

          let grandTotalAmount = salesTotal.reduce((total, current) => {
            return total + current.amount;
          }, 0);
          let grandTotalqty = salesTotal.reduce((total, current) => {
            return total + current.qty;
          }, 0);

          _alumniTotal.Values = salesTotal.reduce((arr, current) => {
            const values = JSON.parse(JSON.stringify(current.Values));

            if (arr.length === 0) {
              arr = [...values];
            } else {
              for (const value of values) {
                if (value.billing_account_columns_id > 1) {
                  const valueIndex = arr.findIndex((a) => a.billing_account_columns_id === value.billing_account_columns_id);

                  const column = self.Columns.find((_c) => value.billing_account_columns_id === _c.id);
                  if (column) {
                    if (column.type !== "personal") {
                      arr[valueIndex].amount = arr[valueIndex].amount + value.amount;
                      arr[valueIndex].qty = arr[valueIndex].qty + value.qty;
                    } else {
                      arr[valueIndex].qty = arr[valueIndex].qty + value.qty;
                    }
                  }
                }
              }
            }

            return arr;
          }, []);

          const [totalAmounts, totalQtys] = salesTotal.reduce(
            (arr, current) => {
              const values = JSON.parse(JSON.stringify(current.Values));
              const valueObj = values.find((c) => c.billing_account_columns_id === 1);

              arr[0] = arr[0] + valueObj.amount;
              arr[1] = arr[1] + valueObj.qty;

              return arr;
            },
            [0, 0]
          );

          const [totalAmount, totalQty] = _alumniTotal.Values.reduce(
            (acc, current) => {
              const column = self.Columns.find((_c) => current.billing_account_columns_id === _c.id);

              if (column && current.billing_account_columns_id !== 1) {
                if (column.type !== "personal") {
                  acc[0] = acc[0] + current.amount;
                  acc[1] = acc[1] + current.qty;
                } else {
                  acc[1] = acc[1] + current.qty;
                }
              }

              return acc;
            },
            [0, 0]
          );

          _alumniTotal.Values.map((av) => {
            if (av.billing_account_columns_id === 1) {
              av.amount = totalAmount;
              av.qty = totalQty;
            }
          });

          _alumniTotal.difference.amount = totalAmount - totalAmounts;
          _alumniTotal.difference.qty = totalQty - totalQtys;

          _alumniTotal.amount = isSalesTable ? grandTotalAmount : totalAmount;
          _alumniTotal.qty = isSalesTable ? grandTotalqty : totalQty;

          return [_alumniTotal, ..._sales];
        } else {
          return [];
        }
      }).bind(this),

      payments(selectedBusinessUnit, sales) {
        // Temp data
        const temp_cash = this.addValues(this.filterBusinessUnit(this.filterAccountType(getRoot(self).accounts.state, 2, 1), selectedBusinessUnit), "cash");
        const temp_cards = this.addValues(
          this.filterBusinessUnit(this.filterAccountType(getRoot(self).accounts.state, 2, 2), selectedBusinessUnit),
          "temp_cards"
        );
        const temp_voucher = this.addValues(this.filterBusinessUnit(this.filterAccountType(getRoot(self).accounts.state, 2, 3), selectedBusinessUnit));
        const result_temp = [...temp_cash, ...temp_cards, ...temp_voucher];

        // Data
        const cash = this.generateObjectTitle(["KASSE", "TOTAL"], temp_cash);
        const cards = this.generateObjectTitle(["KARTEN", "TOTAL"], temp_cards);
        const voucher = this.generateObjectTitle(["GUTSCHEINE ODER RECHNUNG", "TOTAL"], temp_voucher);
        const result = this.calculateChange([...cash, ...cards, ...voucher]);

        // Data for comparison
        const sales_data = this.addValues(this.filterBusinessUnit(this.filterAccountType(getRoot(self).accounts.state, 1), selectedBusinessUnit));
        const comparison = [
          {
            key: -3,
            name: "Prüfung",
            // name: 'COMPARISON',
            Values: this.filtered_columns.map((e) => {
              // Get Sum of employees from zahlungskonten
              const sum_of_employees_payments = this.getSumOfAccount(result_temp, e.id).amount;
              // Get Sum of Employees from umsaktzkonto
              const sum_of_employees_sales = this.getSumOfAccount(sales_data, e.id).amount;

              return {
                billing_account_columns_id: e.id,
                amount: sum_of_employees_sales - sum_of_employees_payments,
              };
            }),
          },
        ];

        // Update 'ang' property of sales account, if values under order is not null || 0, set to 100%, otherwise 0%
        this.updateTotalAng(result, "payment");

        const _payments = JSON.parse(JSON.stringify([...result, ...comparison]));

        let _alumniTotal = {
          Values: [],
          amount: 0,
          ang: "100%",
          difference: { qty: 0, amount: 0 },
          isStatic: 1,
          key: "TOTAL",
          name: "Grand Total",
          garate_amount: 0,
          qty: 0,
        };

        for (const _pT of _payments) {
          if (_pT.name === "TOTAL") {
            const paymentValues = JSON.parse(JSON.stringify(_pT.Values));

            paymentValues.forEach((_pTV, index) => {
              if (_alumniTotal.Values.length === _pT.Values.length) {
                _alumniTotal.Values[index].amount = _alumniTotal.Values[index].amount + _pTV.amount;
              } else {
                _alumniTotal.Values = [..._alumniTotal.Values, _pTV];
              }
            });

            const [_total, _gerate] = _pT.Values.reduce(
              (acc, obj) => {
                const column = this.Columns.find((_c) => obj.billing_account_columns_id === _c.id);
                if (column) {
                  if (column.type !== "gerate") {
                    acc[0] += obj.amount;
                  } else {
                    acc[1] += obj.amount;
                  }
                } else {
                  acc[0] += obj.amount;
                }
                return acc;
              },
              [0, 0]
            );

            _pT.difference.amount = _total - _gerate;

            _pT.amount = _total;
            _pT.garate_amount = _gerate;

            _alumniTotal.amount += _total;

            _alumniTotal.garate_amount += _gerate;
          } else {
            if (_pT.id) {
              const [_total, _gerate] = _pT.Values.reduce(
                (acc, obj) => {
                  const column = this.Columns.find((_c) => obj.billing_account_columns_id === _c.id);
                  if (column) {
                    if (column.type !== "gerate" && column.type !== "personal") {
                      acc[0] += obj.amount;
                    } else {
                      acc[1] += obj.amount;
                    }
                  } else {
                    if (obj.billing_account_columns_id !== 1) {
                      acc[0] += obj.amount;
                    }
                  }
                  return acc;
                },
                [0, 0]
              );
              //if payment type id is KASSE or GUTSCHEINE ODER RECHNUNG, set difference amount to 0
              if (_pT.payment_type_id === 3 || _pT.payment_type_id === 1) {
                _pT.difference.amount = 0;
              } else {
                _pT.difference.amount = _total - _gerate;
              }
              //_pT.difference.amount = _total - _gerate;

              _pT.amount = _total;
              _pT.garate_amount = _gerate;
            }
          }
        }

        _alumniTotal.Values.map((_a) => {
          if (_a.billing_account_columns_id === 1) {
            _a.amount = _alumniTotal.amount;
          }
        });

        _alumniTotal.difference.amount = _alumniTotal.amount - _alumniTotal.garate_amount;

        if (_payments.length > 0) {
          const salesTotal = self.sales(selectedBusinessUnit)[0];
          if (salesTotal) {
            _payments[_payments.length - 1].amount = salesTotal.amount - _alumniTotal.amount;
          }
        }

        //CALCULATING THE GRANDTOTAL AMOUNT FROM employee and personal type only
        let KASSE = "KASSE";
        let RECHNUNG = "GUTSCHEINE ODER RECHNUNG";
        let KARTEN = "KARTEN";
        let grandTotal = 0;
        let gerateTotal = 0;
        _alumniTotal.Values.forEach((obj) => {
          const column = this.Columns.find((_c) => obj.billing_account_columns_id === _c.id);

          if (column) {
            if (column.type !== "gerate" && column.type !== "personal") {
              grandTotal += obj.amount;
            } else {
              gerateTotal += obj.amount;
            }
          }
        });
        _alumniTotal.amount = grandTotal;
        _alumniTotal.difference.amount = grandTotal - gerateTotal;
        let updated_payments = _payments.map((payment) => {
          if (payment.name === "TOTAL") {
            let necesaryType = payment.Values.filter((b) => {
              let column = this.Columns.find((_c) => b.billing_account_columns_id === _c.id);

              return b.billing_account_columns_id !== 1 && column.type !== "gerate" && column.type !== "personal";
            });
            let gerateType = payment.Values.filter((b) => {
              let column = this.Columns.find((_c) => b.billing_account_columns_id === _c.id);
              if (column) {
                return column.type === "gerate";
              }
              return false;
            });
            let amount = necesaryType.reduce((total, current) => {
              return total + current.amount;
            }, 0);

            let gerateTotal = gerateType.reduce((total, current) => {
              return total + current.amount;
            }, 0);

            return {
              ...payment,
              amount,
              difference: { ...payment.difference, amount: payment.groupType === KASSE || payment.groupType === RECHNUNG ? 0 : amount - gerateTotal },
            };
          } else {
            if (payment?.PaymentType?.id === 1) {
              return { ...payment, difference: { ...payment.difference, amount: "" } };
            }
            return payment;
          }
        });

        let getTotalOfKasse = updated_payments.find((c) => c.groupType === KASSE && c.name === "TOTAL");
        //get total difference value of KARTEN
        let getTotalOfKarten = updated_payments.find((c) => c.groupType === KARTEN && c.name === "TOTAL");

        //_alumniTotal.difference.amount = _alumniTotal?.amount - _alumniTotal?.garate_amount - getTotalOfKasse?.amount;

        _alumniTotal.difference.amount = getTotalOfKarten?.difference.amount || 0;

        //console.log(" _alumniTotal", _alumniTotal, _alumniTotal?.garate_amount, getTotalOfKasse?.amount);

        let payments = [_alumniTotal, ...updated_payments];

        if (sales) {
          payments = payments.map((payment) => {
            //IF ROW IS PRUFUNG
            if (payment.key === -3 || payment.name === "Prüfung") {
              let getTotalOfPayments = payments.find((c) => c.key === "TOTAL" && c.name === "Grand Total");
              let getTotalOfSales = sales.find((c) => c.key === "TOTAL" && c.name === "Grand Total");

              return { ...payment, amount: getTotalOfSales?.amount - getTotalOfPayments?.amount };
            }
            return payment;
          });
        }

        return payments;
      },

      get filteredEihnnamen() {
        const rootStore = getRoot(self);
        return this.filterBusinessUnit(this.filterAccountType(rootStore.accounts.state, 3), rootStore.billings.selectedBusinessUnit);
      },

      get totalUmsatzkontenQTY() {
        return self.umsatzkonten.reduce((accumulator, umsatzkonten) => {
          if (umsatzkonten.name === "TOTAL") {
            return accumulator;
          }
          if (umsatzkonten.name !== "TOTAL") {
            return accumulator + umsatzkonten.qty;
          }
        }, 0);
      },
      get totalUmsatzkontenAmount() {
        return self.umsatzkonten.reduce((accumulator, umsatzkonten) => {
          if (umsatzkonten.name === "TOTAL") {
            return accumulator;
          }
          if (umsatzkonten.name !== "TOTAL") {
            return accumulator + umsatzkonten.amount;
          }
        }, 0);
      },

      get totalZahlungskontenQTY() {
        return self.zahlungskonten.reduce((accumulator, zahlungskonten) => {
          if (zahlungskonten.name === "TOTAL" || zahlungskonten.name === "KASSE") {
            return accumulator;
          }
          if (zahlungskonten.name !== "TOTAL" && zahlungskonten.name !== "KASSE") {
            return accumulator + zahlungskonten.qty;
          }
        }, 0);
      },
      get totalZahlungskontenAmount() {
        return self.zahlungskonten.reduce((accumulator, zahlungskonten) => {
          if (zahlungskonten.name === "TOTAL" || zahlungskonten.name === "KASSE") {
            return accumulator;
          }
          if (zahlungskonten.name !== "TOTAL" && zahlungskonten.name !== "KASSE") {
            return accumulator + zahlungskonten.amount;
          }
        }, 0);
      },

      get incomeAndExpenseRow() {
        // let newZahlungskonten = this.zahlungskonten.map((c) => {
        //   let newValues = c.Values.filter((b) => b.billing_account_columns_id !== 1);

        //   let totalAmount = newValues.reduce((total, current) => total + current.amount, 0);

        //   return { ...c, Values: newValues, amount: totalAmount };
        // });
        // let fnd = newZahlungskonten.find((e) => e.payment_type_id === 1 && e.isStatic && !e.isTitle);
        // console.log(fnd, newZahlungskonten, "newZahlungskonten");
        const kasse_amount = (this.zahlungskonten.find((e) => e.payment_type_id === 1 && e.isStatic && !e.isTitle) || { amount: 0 }).amount;
        let newEihnamen = self.eihnnamen.map((c) => {
          // let newValues = c.Values.filter((b) => b.Account?.isIncome === 0);
          let totalAmount = c.Values.reduce((total, current) => total + current.amount, 0);
          let totalQty = c.Values.reduce((total, current) => total + current.qty, 0);
          return { ...c, amount: totalAmount, qty: totalQty };
        });
        //console.log("self.eihnnamen", self.eihnnamen.toJSON());
        return [
          {
            key: "asbfdrer",
            name: "Vortagessaldo",
            id: "PreviousDaySaldo",
            // qty: 0, // ???
            amount: this.previousSaldo, // ???
          },
          {
            key: "gfhfgdfgdf",
            name: "Total Einnahmen",
            id: "TotalEinnahmen",
            // qty: self.eihnnamen.filter((e) => e.Account?.isIncome === 1).reduce((accumulator, currentValue) => accumulator + currentValue.qty, 0) || 0,
            amount:
              parseFloat(
                newEihnamen.filter((e) => e.Account?.isIncome === 1).reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0) || 0
              ) + kasse_amount,
          },
          {
            key: "asgdfgfdbfdrer",
            name: "Total Ausgaben",
            id: "TotalAusgaben",
            // qty: self.eihnnamen.filter((e) => e.Account?.isIncome === 0).reduce((accumulator, currentValue) => accumulator + currentValue.qty, 0) || 0,
            amount: newEihnamen.filter((e) => e.Account?.isIncome === 0).reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0) || 0,
          },
          {
            key: "sfdgfdsgdfg",
            name: "Aktueller Saldo",
            id: "CurrentSaldo",
            // qty: !!self.umsatzkonten
            // 	? 0 + // This should be previous saldo
            // 	  kasse_amount +
            // 	  (self.eihnnamen.filter((e) => e.Account?.isIncome === 1).reduce((accumulator, currentValue) => accumulator + currentValue.qty, 0) -
            // 			self.eihnnamen.filter((e) => e.Account?.isIncome === 0).reduce((accumulator, currentValue) => accumulator + currentValue.qty, 0))
            // 	: 0,
            amount: !!self.zahlungskonten
              ? this.previousSaldo + // This should be previous saldo
                kasse_amount +
                (newEihnamen.filter((e) => e.Account?.isIncome === 1).reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0) -
                  newEihnamen.filter((e) => e.Account?.isIncome === 0).reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0))
              : 0,
          },
          // -REMOVE (feedback from Ma'am Angela #449)
          // {
          // 	key: 'fdgsdfg',
          // 	name: 'Prüfung/Vergleich',
          // 	id: 'ComparisonIncome',
          // 	// qty:
          // 	// 	self.eihnnamen.filter((e) => e.Account?.isIncome === 1).reduce((accumulator, currentValue) => accumulator + currentValue.qty, 0) -
          // 	// 	self.eihnnamen.filter((e) => e.Account?.isIncome === 0).reduce((accumulator, currentValue) => accumulator + currentValue.qty, 0),
          // 	amount:
          // 		self.eihnnamen.filter((e) => e.Account?.isIncome === 1).reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0) -
          // 		self.eihnnamen.filter((e) => e.Account?.isIncome === 0).reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0),
          // },
        ];
      },

      get incomeAndExpenseWeekly() {
        let rootStore = getRoot(self);

        const getEinnahmen = (dayname, isIncome, payment_type_id) => {
          const weeklyEinnahmen = self.einnahmen.filter((ele) => ele.dayname === dayname && ele.isIncome === isIncome);

          // console.log(self.einnahmen.toJSON(), self.zahlungskonten.toJSON(), { dayname, isIncome, payment_type_id }, "weeklyEinnahmen");
          const totalDay = weeklyEinnahmen.reduce((total, current) => {
            return total + current.amount;
          }, 0);

          const pti = self.zahlungskonten.filter((ele) => {
            return ele.payment_type_id === payment_type_id;
          });

          const totalkasse = pti.reduce((total, current) => {
            return total + current[dayname].amount;
          }, 0);
          if (dayname === "Saturday") {
            //console.log(weeklyEinnahmen, self.einnahmen.toJSON(), pti, "pti");
            console.log(pti, "einhamen");
            console.log(payment_type_id, isIncome, "check");
          }

          return totalDay + totalkasse;
        };

        const getAusgaben = (dayname, isIncome) => {
          const weeklyAusgaben = self.einnahmen.filter((ele) => ele.dayname === dayname && ele.isIncome === isIncome);
          const totalDay = weeklyAusgaben.reduce((total, current) => {
            return total + current.amount;
          }, 0);
          return totalDay;
        };

        const calculateVortagessaldo = (previousSaldo, dayName) => {
          return previousSaldo + getEinnahmen(dayName, 1, 1) - getAusgaben(dayName, 0);
        };

        let mondayAmountVortagessaldo = 0;
        let tuesdayAmountVortagessaldo = 0;
        let wednesdayAmountVortagessaldo = 0;
        let thursdayAmountVortagessaldo = 0;
        let fridayAmountVortagessaldo = 0;
        let saturdayAmountVortagessaldo = 0;
        let sundayAmountVortagessaldo = 0;

        let mondayAmountAktuellerSaldo = 0;
        let tuesdayAmountAktuellerSaldo = 0;
        let wednesdayAmountAktuellerSaldo = 0;
        let thursdayAmountAktuellerSaldo = 0;
        let fridayAmountAktuellerSaldo = 0;
        let saturdayAmountAktuellerSaldo = 0;
        let sundayAmountAktuellerSaldo = 0;
        if (rootStore.language === "de") {
          mondayAmountVortagessaldo = self.previousSaldo;
          mondayAmountAktuellerSaldo = calculateVortagessaldo(mondayAmountVortagessaldo, "Monday");

          tuesdayAmountVortagessaldo = mondayAmountAktuellerSaldo;
          tuesdayAmountAktuellerSaldo = calculateVortagessaldo(tuesdayAmountVortagessaldo, "Tuesday");

          wednesdayAmountVortagessaldo = tuesdayAmountAktuellerSaldo;
          wednesdayAmountAktuellerSaldo = calculateVortagessaldo(wednesdayAmountVortagessaldo, "Wednesday");

          thursdayAmountVortagessaldo = wednesdayAmountAktuellerSaldo;
          thursdayAmountAktuellerSaldo = calculateVortagessaldo(thursdayAmountVortagessaldo, "Thursday");

          fridayAmountVortagessaldo = thursdayAmountAktuellerSaldo;
          fridayAmountAktuellerSaldo = calculateVortagessaldo(fridayAmountVortagessaldo, "Friday");

          saturdayAmountVortagessaldo = fridayAmountAktuellerSaldo;
          saturdayAmountAktuellerSaldo = calculateVortagessaldo(saturdayAmountVortagessaldo, "Saturday");

          sundayAmountVortagessaldo = saturdayAmountAktuellerSaldo;
          sundayAmountAktuellerSaldo = calculateVortagessaldo(sundayAmountVortagessaldo, "Sunday");
        } else {
          sundayAmountVortagessaldo = self.previousSaldo;
          mondayAmountVortagessaldo = calculateVortagessaldo(sundayAmountVortagessaldo, "Sunday");

          mondayAmountAktuellerSaldo = calculateVortagessaldo(mondayAmountVortagessaldo, "Monday");
          tuesdayAmountVortagessaldo = mondayAmountAktuellerSaldo;

          tuesdayAmountAktuellerSaldo = calculateVortagessaldo(mondayAmountAktuellerSaldo, "Tuesday");
          wednesdayAmountVortagessaldo = tuesdayAmountAktuellerSaldo;

          wednesdayAmountAktuellerSaldo = calculateVortagessaldo(wednesdayAmountVortagessaldo, "Wednesday");
          thursdayAmountVortagessaldo = wednesdayAmountAktuellerSaldo;

          thursdayAmountAktuellerSaldo = calculateVortagessaldo(wednesdayAmountAktuellerSaldo, "Thursday");
          fridayAmountVortagessaldo = thursdayAmountAktuellerSaldo;

          fridayAmountAktuellerSaldo = calculateVortagessaldo(thursdayAmountAktuellerSaldo, "Friday");
          saturdayAmountVortagessaldo = fridayAmountAktuellerSaldo;

          saturdayAmountAktuellerSaldo = calculateVortagessaldo(fridayAmountAktuellerSaldo, "Saturday");
          sundayAmountAktuellerSaldo = calculateVortagessaldo(sundayAmountVortagessaldo, "Sunday");
        }

        let totalEinamenAmount =
          getEinnahmen("Monday", 1, 1) +
          getEinnahmen("Tuesday", 1, 1) +
          getEinnahmen("Wednesday", 1, 1) +
          getEinnahmen("Thursday", 1, 1) +
          getEinnahmen("Friday", 1, 1) +
          getEinnahmen("Saturday", 1, 1) +
          getEinnahmen("Sunday", 1, 1);

        let totalAusgabenAmount =
          getAusgaben("Monday", 0) +
          getAusgaben("Tuesday", 0) +
          getAusgaben("Wednesday", 0) +
          getAusgaben("Thursday", 0) +
          getAusgaben("Friday", 0) +
          getAusgaben("Saturday", 0) +
          getAusgaben("Sunday", 0);

        return [
          {
            key: "asbfdrer",
            name: "Vortagessaldo",
            id: "PreviousDaySaldo",
            amount: rootStore.language === "de" ? mondayAmountVortagessaldo : sundayAmountVortagessaldo,
            // mondayAmountVortagessaldo +
            // tuesdayAmountVortagessaldo +
            // wednesdayAmountVortagessaldo +
            // thursdayAmountVortagessaldo +
            // fridayAmountVortagessaldo +
            // saturdayAmountVortagessaldo +
            // sundayAmountVortagessaldo,
            Monday: {
              amount: mondayAmountVortagessaldo,
            },
            Tuesday: { amount: tuesdayAmountVortagessaldo },
            Wednesday: { amount: wednesdayAmountVortagessaldo },
            Thursday: { amount: thursdayAmountVortagessaldo },
            Friday: {
              amount: fridayAmountVortagessaldo,
            },
            Saturday: {
              amount: saturdayAmountVortagessaldo,
            },
            Sunday: {
              amount: sundayAmountVortagessaldo,
            },
          },
          {
            key: "gfhfgdfgdf",
            name: "Total Einnahmen",
            id: "TotalEinnahmen",
            amount: totalEinamenAmount,
            Monday: { amount: getEinnahmen("Monday", 1, 1) },
            Tuesday: { amount: getEinnahmen("Tuesday", 1, 1) },
            Wednesday: { amount: getEinnahmen("Wednesday", 1, 1) },
            Thursday: { amount: getEinnahmen("Thursday", 1, 1) },
            Friday: { amount: getEinnahmen("Friday", 1, 1) },
            Saturday: { amount: getEinnahmen("Saturday", 1, 1) },
            Sunday: { amount: getEinnahmen("Sunday", 1, 1) },
          },
          {
            key: "asgdfgfdbfdrer",
            name: "Total Ausgaben",
            id: "TotalAusgaben",
            amount: totalAusgabenAmount,
            Monday: { amount: getAusgaben("Monday", 0) },
            Tuesday: { amount: getAusgaben("Tuesday", 0) },
            Wednesday: { amount: getAusgaben("Wednesday", 0) },
            Thursday: { amount: getAusgaben("Thursday", 0) },
            Friday: { amount: getAusgaben("Friday", 0) },
            Saturday: { amount: getAusgaben("Saturday", 0) },
            Sunday: { amount: getAusgaben("Sunday", 0) },
          },
          {
            key: "sfdgfdsgdfg",
            name: "Aktueller Saldo",
            id: "CurrentSaldo",
            amount:
              rootStore.language === "de"
                ? mondayAmountVortagessaldo + totalEinamenAmount - totalAusgabenAmount
                : sundayAmountVortagessaldo + totalEinamenAmount - totalAusgabenAmount,
            Monday: { amount: mondayAmountAktuellerSaldo },
            Tuesday: { amount: tuesdayAmountAktuellerSaldo },
            Wednesday: { amount: wednesdayAmountAktuellerSaldo },
            Thursday: {
              amount: thursdayAmountAktuellerSaldo,
            },
            Friday: {
              amount: fridayAmountAktuellerSaldo,
            },
            Saturday: {
              amount: saturdayAmountAktuellerSaldo,
            },
            Sunday: {
              amount: sundayAmountAktuellerSaldo,
            },
          },
        ];
      },
    };
  })
  .actions((self) => ({
    SET(property, value) {
      self[property] = value;
    },

    SET_COLUMNS(data) {
      self.Columns.splice(
        self.Columns.findIndex((e) => e.id === data.id),
        1,
        data
      );
    },

    DELETE: flow(function* (id, type) {
      yield axios.delete(`/api/billings/${id}/${type}`);

      if (type === "account") {
        self.BillingAccounts.splice(
          self.BillingAccounts.findIndex((data) => data.id === id),
          1
        );
      } else if (type === "comment") {
        self.Comments.splice(
          self.Comments.findIndex((data) => data.id === id),
          1
        );
      }
    }),
  }));

// const getGrandTotal = (objTotal) => {
//    let _alumniTotal = {
//       Values: [],
//       amount: 0,
//       ang: "100%",
//       difference: { qty: 0, amount: 0 },
//       isStatic: 1,
//       key: "TOTAL",
//       name: t("Grand Total"),
//       qty: 0,
//    };

//    for (const _pT of objTotal) {
//       for (const _pTV of _pT.Values) {
//          const _vIndex = _alumniTotal.Values.findIndex((_aV) => _aV.billing_account_columns_id === _pTV.billing_account_columns_id);
//          const _imp = props.store.billings.billing.Columns.find((_c) => _pTV.billing_account_columns_id === _c.id);

//          if (_vIndex === -1) {
//             _alumniTotal.Values.push(_pTV);
//          } else {
//             if (_pTV) {
//                _alumniTotal.Values[_vIndex].amount += _pTV.amount;
//                _alumniTotal.Values[_vIndex].qty += _pTV.qty;
//             }
//          }
//       }

//       _alumniTotal.amount += _pT.amount;
//       _alumniTotal.qty += _pT.qty;
//    }

//    return _alumniTotal;
// };

// useEffect(() => {
//    setColumnPayments(props.columnPayments);
//    setPaymentDataSource((_) => {
//       const _payments = JSON.parse(JSON.stringify(props.dataSource.payments(props.selectedBusinessUnit)));
//       const _paymentTotals = JSON.parse(JSON.stringify(_payments.filter((_pd) => _pd.name === "TOTAL")));

//       const _alumniTotal = getGrandTotal(_paymentTotals);

//       _payments.unshift(_alumniTotal);

//       return _payments;
//    });

//    setSalesDataSource((_) => {
//       const _sales = JSON.parse(JSON.stringify(props.dataSource.sales(props.selectedBusinessUnit)));
//       const _salesTotals = JSON.parse(JSON.stringify(_sales.filter((_pd) => _pd.name === "TOTAL")));

//       const _alumniTotal = getGrandTotal(_salesTotals);

//       _sales.unshift(_alumniTotal);

//       return _sales;
//    });

//    setCalculationsDataSource((_) => {
//       const _calculation = JSON.parse(JSON.stringify(props.dataSource.calculations(true)));
//       const [_calculationTotals] = JSON.parse(JSON.stringify(_calculation.filter((_pd) => _pd.name === "Anzahl Gäste")));

//       const _totalQty = _calculationTotals.Values.reduce((acc, obj) => {
//          if (obj.billing_account_columns_id !== 1) {
//             acc += obj.qty;
//          }

//          return acc;
//       }, 0);

//       const _calculationAnzhal = _calculationTotals.Values.map((_) => {
//          if (_.billing_account_columns_id === 1) {
//             _.qty = _totalQty;
//          }

//          return _;
//       });

//       _calculation[0].Values = _calculationAnzhal;

//       return _calculation;
//    });

//    return () => {
//       setColumnPayments([]);
//       setPaymentDataSource([]);
//       setSalesDataSource([]);
//       setCalculationsDataSource([]);
//    };
// }, [props.store.language, props.columnPayments]);
