import { types, flow, cast } from "mobx-state-tree";

export default types
  .model("HomeBaseModel", {
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    // kostentelle: types.optional(types.maybeNull(types.number), 0),
    // account_soll_haben: types.optional(types.maybeNull(types.string), ""),
    // address: types.optional(types.string, ""),
  })
  .actions((self) => ({}));
