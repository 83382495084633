import { types, flow, cast } from "mobx-state-tree";
import axios from "axios";

import LocalUnitsModel from "./LocalUnitsModel";

export default types
  .model("LocalUnitsState", {
    state: types.optional(types.array(LocalUnitsModel(true)), []),
    updatedAt: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    RESET_DROPDOWN_SETTING(value) {
      self.state = [];
    },

    SET_UPDATED_AT() {
      self.updatedAt = new Date().getTime().toString();
    },

    FETCH_DATA: flow(function* (isActive, user_id = "") {
      self.loading = true;

      const { data } = yield axios.get(`/api/localUnits/${isActive ? "active" : ""}/${user_id}`);
      self.state = cast(data);

      self.loading = false;
      return data;
    }),

    FIND_LOCALUNITS: flow(function* (id) {
      const result = self.state.find((unit) => unit.id === id);
      return result;
    }),

    ADD: flow(function* (values) {
      const { data } = yield axios.post("/api/localUnits", values);
      self.state.push(data);
    }),

    UPDATE: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/localUnits/${id}`, values);
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1,
        data
      );
    }),

    UPDATE_STATUS: flow(function* (values) {
      yield axios.post(`/api/localUnits/status`, values);
    }),

    DELETE: flow(function* (id) {
      yield axios.delete("/api/localUnits", { data: { ids: [id] } });
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
    }),
  }));
