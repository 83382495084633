import { types, flow, cast, getParent } from "mobx-state-tree";
import axios from "axios";
//Model
import EmployeeModel from "./EmployeeModel";
import moment from "moment";

export default types
  .model("EmployeeManagementSTATE", {
    state: types.optional(types.array(EmployeeModel), []),
    updatedAt: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    employees(selectedBusinessUnit) {
      // Add static columns
      const employees = (
        this.state
          .filter((e) =>
            e.BusinessUnits.toJSON()
              .map((b) => b.id)
              .includes(selectedBusinessUnit)
          )
          .filter((e) => e.BusinessUnits.find((b) => b.id === selectedBusinessUnit).isActive && e.Settings.length !== 0) ?? []
      ).concat([
        { id: -1, work_name: "Personal", type: "personal", isActive: 1, Settings: { length: 1 } },
        { id: -2, work_name: "Takeaway", type: "takeaway", isActive: 1, Settings: { length: 1 } },
      ]);

      return employees;
    },
    employeesAvailable(selectedBusinessUnit) {
      const employees = (
        this.state
          .filter((e) =>
            e.BusinessUnits.toJSON()
              .map((b) => b.id)
              .includes(selectedBusinessUnit)
          )
          .filter(
            (e) =>
              e.BusinessUnits.find((b) => b.id === selectedBusinessUnit).isActive &&
              e.Settings.length !== 0 &&
              e.Settings.find((dropdownSetting) => dropdownSetting.settings_type_id === 3) &&
              e.Settings.find((dropdownSetting) => dropdownSetting.value === "1")
          ) ?? []
      ).concat([
        { id: -1, work_name: "Personal", type: "personal", isActive: 1, Settings: { length: 1 } },
        { id: -2, work_name: "Takeaway", type: "takeaway", isActive: 1, Settings: { length: 1 } },
      ]);

      return employees;
    },

    availableEmployees(employee_id) {
      const columns = getParent(self).billings.billing.Columns.toJSON();
      const selectedBusinessUnit = getParent(self).billings.selectedBusinessUnit;
      return this.employees(selectedBusinessUnit).filter((e) => !columns.find((column) => column.employee_id === e.id) || employee_id === e.id);
    },

    hasRemainingEmployees(selectedBusinessUnit) {
      const columns = getParent(self).billings.billing.Columns.toJSON();
      return this.employees(selectedBusinessUnit).filter((e) => !columns.find((column) => column.employee_id === e.id)).length !== 0;
    },

    // getNextEmployee(selectedBusinessUnit, counter) {
    //   const columns = getParent(self).billings.billing.Columns.toJSON();
    //   return this.employees(selectedBusinessUnit).filter((e) => !columns.find((column) => column.employee_id === e.id))[0 + counter];
    // },
    getNextEmployee(selectedBusinessUnit, counter) {
      const columns = getParent(self).billings.billing.Columns.toJSON();

      /**FIXING BUG WHERE COLUMNS BECOME A NUMBER
       * CHOOSING SPECIC TYPE OF EMPLOYEE WHERE dropdownSetting.settings_type_id === 3 and
       *  dropdownSetting.value === "1"
       */
      let filtered = this.employees(selectedBusinessUnit).filter((employee) => {
        if (employee.id < 0) {
          return true;
        } else if (employee?.Settings?.find((dropdownSetting) => dropdownSetting.settings_type_id === 3)) {
          if (employee?.Settings?.find((dropdownSetting) => dropdownSetting.value === "1")) {
            return true;
          }

          return false;
        } else {
          return true;
        }
      });
      return filtered.filter((e) => !columns.find((column) => column.employee_id === e.id))[0 + counter];
    },

    get businessUnits() {
      return this.state.reduce((a, b) => {
        return (a = a.concat(b.BusinessUnits?.filter((bu) => !a.find((_bu) => bu.id === _bu.id)) || []));
      }, []);
    },
  }))
  .actions((self) => ({
    RESET_EMPLOYEE(value) {
      self.state = [];
    },
    SET_UPDATED_AT() {
      self.updatedAt = new Date().getTime().toString();
    },

    FETCH_DATA: flow(function* (user_id = "", type, isEmployeePage = false, isShowAll = true) {
      type = !!user_id ? type : "";
      const path = isShowAll ? `/api/employees/showAll/true` : `/api/employees/${user_id}/${type}`;
      self.loading = true;

      let { data } = yield axios.get(path);
      data = isEmployeePage
        ? data.map((e) => {
            // Find duplicate employees, Disable when found
            const found = data.find((s) => s.employee_nr === e.employee_nr) || [];
            return {
              ...e,
              disabled: found.length == 0 && found.length == 1 ? 0 : e.isActive ? 0 : 1,
            };
          })
        : data;
      data.sort((a, b) => a.work_name.localeCompare(b.work_name));
      self.state = cast(data);

      self.loading = false;
    }),

    ADD: flow(function* (values) {
      const { data } = yield axios.post("/api/employees/", values);
      delete data.isActive;
      self.state.push(data);
      self.SET_UPDATED_AT();
    }),

    DELETE: flow(function* (id) {
      yield axios.delete("/api/employees", { data: { ids: [id] } });
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
      self.SET_UPDATED_AT();
    }),

    UPDATE: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/employees/${id}`, values);
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1,
        data
      );
      self.SET_UPDATED_AT();
    }),
    UPDATE_HISTORY: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/employees/updateHistory/${id}`, values);
      // self.state.splice(
      //   self.state.findIndex((data) => data.id === id),
      //   1,
      //   data
      // );
      // self.SET_UPDATED_AT();
    }),
    FETCH_VALID_FROM_DATA: flow(function* (values) {
      const { work_name } = values;
      let { data } = yield axios.get(`/api/employees/getValidFromList/${work_name}`);

      data.sort((a, b) => moment(a.valid_from, "DD.MM.YYYY") - moment(b.valid_from, "DD.MM.YYYY"));

      return data;
    }),
    FETCH_PERSONAL_CATEGORY_LIST: flow(function* (id) {
      let { data } = yield axios.get(`/api/settings/${id}`);
      return data;
    }),

    TOGGLE_STATUS: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/employees/${id}`, values);
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1,
        data
      );
      self.SET_UPDATED_AT();
    }),
  }));
