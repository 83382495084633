import { types, flow, getRoot } from "mobx-state-tree";
import EmployeeModel from "../EmployeeManagement/EmployeeModel";
import CrTypesModel from "./CrTypesModel";

export default types
  .model("CreditsAndReductionsModel", {
    id: types.optional(types.number, 0),
    employee_id: types.optional(types.maybeNull(types.number), 0),
    value: types.optional(types.maybeNull(types.number), 0),
    name: types.optional(types.string, ""),
    cr_name: types.optional(types.string, ""),
    cr_date: types.optional(types.string, ""),
    cr_type: types.optional(types.number, 0),
    isCopy: types.optional(types.number, 0),
    CrTypes: types.optional(CrTypesModel, {}),
    Employee: types.optional(EmployeeModel, {}),
  })
  .views((self) => ({}));
