import { types, flow, cast } from "mobx-state-tree";
import axios from "axios";
import WorkHoursPercentageModel from "./WorkHoursPercentageModel";
import { message } from "antd";

export default types
  .model("WorkHoursPercentageState", {
    state: types.optional(types.array(WorkHoursPercentageModel), []),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get filters() {
      return this.state.map((e) => ({ text: e.name, value: e.id }));
    },
  }))
  .actions((self) => ({
    FETCH_DATA: flow(function* () {
      self.loading = true;

      const { data } = yield axios.get(`/api/workHoursPercentages/`);
      self.state = cast(data);

      self.loading = false;
    }),

    UPDATE: flow(function* (id, payload) {
      const { data } = yield axios.put(`/api/workHoursPercentages/${id}`, payload);
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1,
        data
      );
    }),
    CUSTOM_UPDATE: flow(function* (id, payload) {
      const { data } = yield axios.put(`/api/workHoursPercentages/${id}`, payload);
      let newData = data.data;
      if (data.success) {
        self.state.splice(
          self.state.findIndex((newData) => newData.id === id),
          1,
          newData
        );
        return true;
      } else {
        return false;
      }
    }),

    DELETE: flow(function* (id) {
      const { data } = yield axios.delete(`/api/workHoursPercentages/${id}`);
      self.state.splice(
        self.state.findIndex((data) => data.id === id),
        1
      );
    }),

    ADD: flow(function* (values) {
      const { data } = yield axios.post("/api/workHoursPercentages/", values);
      if (data.success) {
        self.state.push(data.data);
        return true;
      } else {
        return false;
      }
    }),
  }))
  .views((self) => ({}));
