import React, { useEffect, useState } from "react";

//Ant D
import { ConfigProvider } from "antd";
//Moment
import moment from "moment";
//translation
import de_DE from "antd/es/locale/de_DE";
import enUS from "antd/es/locale/en_US";

// enUS.DatePicker.lang.locale = "de_DE";

function GlobalProvider(props) {
  const [locale, setLocale] = useState(localStorage.getItem("locale") || de_DE);
  console.log("🚀 ~ file: GlobalProvider.js ~ line 16 ~ GlobalProvider ~ props", props);

  useEffect(() => {
    if (props?.lang) {
      moment.locale(props.lang);

      if (props.lang === "en") setLocale(enUS);
      if (props.lang === "de") setLocale(de_DE);
    }
  }, [props?.lang]);
  let wow1 = {
    placeholder: "Select time",
    rangePlaceholder: ["Start time", "End time"],
  };
  let wow = {
    placeholder: "Select date",
    yearPlaceholder: "Select year",
    quarterPlaceholder: "Select quarter",
    monthPlaceholder: "Select month",
    weekPlaceholder: "Select week",
    rangePlaceholder: ["Start date", "End date"],
    rangeYearPlaceholder: ["Start year", "End year"],
    rangeMonthPlaceholder: ["Start month", "End month"],
    rangeWeekPlaceholder: ["Start week", "End week"],
    locale: "de_DE",
    today: "Today",
    now: "Now",
    backToToday: "Back to today",
    ok: "Ok",
    clear: "Clear",
    month: "Month",
    year: "Year",
    timeSelect: "select time",
    dateSelect: "select date",
    weekSelect: "Choose a week",
    monthSelect: "Choose a month",
    yearSelect: "Choose a year",
    decadeSelect: "Choose a decade",
    yearFormat: "YYYY",
    dateFormat: "M/D/YYYY",
    dayFormat: "D",
    dateTimeFormat: "M/D/YYYY HH:mm:ss",
    monthBeforeYear: true,
    previousMonth: "Previous month (PageUp)",
    nextMonth: "Next month (PageDown)",
    previousYear: "Last year (Control + left)",
    nextYear: "Next year (Control + right)",
    previousDecade: "Last decade",
    nextDecade: "Next decade",
    previousCentury: "Last century",
    nextCentury: "Next century",
  };
  console.log(enUS, "de_DE");
  return <ConfigProvider locale={locale}>{props?.children}</ConfigProvider>;
}

export default GlobalProvider;
