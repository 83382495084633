import React, { Suspense } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react"; //These functions make our components observable and be able to use the store
import AuthenticatedUsers from "../enums/PrivateRouteUsers";
import "./App.css";
import Layout from "./Layout";
import Spinner from "./common/Spinner";

import GlobalProvider from "../components/common/GlobalProvider";

// import ComponentNotFound from './error/ComponentNotFound';
// import ProtectedRoute from '../components/Route/ProtectedRoute';
// import Login from '../views/Login';

const ComponentNotFound = React.lazy(() => import("./error/ComponentNotFound"));
const ProtectedRoute = React.lazy(() => import("../components/Route/ProtectedRoute"));
const Login = React.lazy(() => import("../views/Login"));

// Admin User
// import DropdownSettings from '../views/DropdownSettings/';
// import TagesabrechnungLocalUnits from '../views/Tagesabrechnung/LocalUnits';
// import TagesabrechnungBusinessUnits from '../views/Tagesabrechnung/BusinessUnits';
// import UserManagement from '../views/UserManagement/';
// import LocalUnits from '../views/LocalUnits/';
// import EmployeeManagement from '../views/EmployeeManagement';
// import TimeManagement from '../views/TimeManagement';
// import AccountCategories from '../views/AccountCategories';
// import SalesAccount from '../views/SalesAccount';
// import PaymentAccount from '../views/PaymentAccount';
// import EinnahmenAusgaben from '../views/EinnahmenAusgaben';

const DropdownSettings = React.lazy(() => import("../views/DropdownSettings/"));
const TagesabrechnungLocalUnits = React.lazy(() => import("../views/Tagesabrechnung/LocalUnits"));
const TagesabrechnungBusinessUnits = React.lazy(() => import("../views/Tagesabrechnung/BusinessUnits"));
const TagesabrechnungWeeklyView = React.lazy(() => import("../views/Tagesabrechnung/WeeklyView"));
const UserManagement = React.lazy(() => import("../views/UserManagement/"));
const AbsenceManagement = React.lazy(() => import("../views/AbsenceManagement/"));
const LocalUnits = React.lazy(() => import("../views/LocalUnits/"));
const EmployeeManagement = React.lazy(() => import("../views/EmployeeManagement"));
const TimeManagement = React.lazy(() => import("../views/TimeManagement"));
const AccountCategories = React.lazy(() => import("../views/AccountCategories"));
const SalesAccount = React.lazy(() => import("../views/SalesAccount"));
const PaymentAccount = React.lazy(() => import("../views/PaymentAccount"));
const EinnahmenAusgaben = React.lazy(() => import("../views/EinnahmenAusgaben"));
const WorkHoursPercentage = React.lazy(() => import("../views/WorkHoursPercentage"));
const WorkControl = React.lazy(() => import("../views/WorkControl"));
const InputMask = React.lazy(() => import("../views/InputMask"));
const ValueMapping = React.lazy(() => import("../views/ValueMapping"));

function App(props) {
  const { store } = props;

  /**
   * @IMPORTANT
   * @REQUIRED_PROP
   * authenticatedUserTypes={[1,2,3,4,5]} -> Meaning: all user types can access this route
   */

  /**
   *   ====== User Types ======
   *   1 = Administrator
   *   2 = Standard User
   *   3 = Finance User
   *   4 = HR User
   *   5 = HR & Finance User
   */

  return (
    <GlobalProvider lang={store.language}>
      <BrowserRouter>
        <Layout store={store}>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/login" render={(props) => (store.login.isLoggedIn ? <Redirect to={{ pathname: "/" }} /> : <Login store={store} />)} />
              {store.login.user_type_id !== 4 && (
                <ProtectedRoute
                  exact
                  path="/(|Tagesabrechnung/LocalUnits)"
                  component={TagesabrechnungLocalUnits}
                  store={store}
                  authenticatedUserTypes={AuthenticatedUsers["Tagesabrechnung"]}
                />
              )}
              {store.login.user_type_id !== 4 && (
                <ProtectedRoute
                  exact
                  path="/Tagesabrechnung/BusinessUnits/"
                  component={TagesabrechnungBusinessUnits}
                  store={store}
                  authenticatedUserTypes={AuthenticatedUsers["Tagesabrechnung"]}
                />
              )}

              <ProtectedRoute
                exact
                path="/Tagesabrechnung/WeeklyView/"
                component={TagesabrechnungWeeklyView}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Tagesabrechnung"]}
              />

              {/* <ProtectedRoute exact path="/Standard" component={StandardTagesabrechnung} store={store} /> */}
              <ProtectedRoute exact path="/(|Employee)/" component={EmployeeManagement} store={store} authenticatedUserTypes={AuthenticatedUsers["Employee"]} />
              <ProtectedRoute
                exact
                path="/TimeManagement"
                component={TimeManagement}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["TimeManagement"]}
              />

              <ProtectedRoute exact path="/LocalUnits" component={LocalUnits} store={store} authenticatedUserTypes={AuthenticatedUsers["Administrator"]} />
              <ProtectedRoute
                exact
                path="/UserManagement"
                component={UserManagement}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Administrator"]}
              />
              <ProtectedRoute
                exact
                path="/Dropdownsetting"
                component={DropdownSettings}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Administrator"]}
              />
              <ProtectedRoute
                exact
                path="/AccountCategories"
                component={AccountCategories}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Administrator"]}
              />
              <ProtectedRoute exact path="/SalesAccount" component={SalesAccount} store={store} authenticatedUserTypes={AuthenticatedUsers["Administrator"]} />
              <ProtectedRoute
                exact
                path="/PaymentAccount"
                component={PaymentAccount}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Administrator"]}
              />
              <ProtectedRoute exact path="/Eingabemaske" component={InputMask} store={store} authenticatedUserTypes={AuthenticatedUsers["Absence"]} />
              <ProtectedRoute
                exact
                path="/EinnahmenAusgaben"
                component={EinnahmenAusgaben}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Administrator"]}
              />
              <ProtectedRoute
                exact
                path="/AbsenceManagement"
                component={AbsenceManagement}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Absence"]}
              />
              <ProtectedRoute
                exact
                path="/WorkHoursPercentage"
                component={WorkHoursPercentage}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Administrator"]}
              />
              <ProtectedRoute
                exact
                path="/Tagesabrechnung/WorkControl"
                component={WorkControl}
                store={store}
                authenticatedUserTypes={AuthenticatedUsers["Absence"]}
              />
              <ProtectedRoute exact path="/ValueMapping" component={ValueMapping} store={store} authenticatedUserTypes={AuthenticatedUsers["ValueMapping"]} />
              <Route component={ComponentNotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default inject("store")(observer(App));
