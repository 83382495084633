import { types, flow, cast } from "mobx-state-tree";

const AccountDebitValuesModel = types
   .model("AccountDebitValuesModel", {
      id: types.optional(types.number, 0),
      accountId: types.optional(types.number, 0),
      localUnitId: types.optional(types.number, 0),
      debit: types.optional(types.number, 0),
   })
   .actions((self) => ({}));
export default AccountDebitValuesModel;
