import React, { useState, useEffect } from "react";
import "./style.css";
import AuthenticatedUsers from "../../enums/PrivateRouteUsers";
import { Menu } from "antd";
import {
  ApartmentOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  UserOutlined,
  UserSwitchOutlined,
  ContainerOutlined,
  CalculatorOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

function AdminMenu(props) {
  const { t } = useTranslation("common");

  const menuRoutes = {
    "Tagesabrechnung/LocalUnits": "/Tagesabrechnung/LocalUnits",
    "Tagesabrechnung/BusinessUnits": "/Tagesabrechnung/BusinessUnits",
    "Tagesabrechnung/WeeklyView": "/Tagesabrechnung/WeeklyView",
    Employee: "/Employee",
    TimeManagement: "/TimeManagement",
    LocalUnits: "/LocalUnits",
    Income: "/EinnahmenAusgaben",
    AccountCategories: "/AccountCategories",
    Payment: "/PaymentAccount",
    WorkControl: "/Tagesabrechnung/WorkControl",
    Sales: "/SalesAccount",
    User: "/UserManagement",
    Absence: "/AbsenceManagement",
    InputMask: "/Eingabemaske",
    WorkHoursPercentage: "/WorkHoursPercentage",
    ValueMapping: "/ValueMapping",
  };
  let history = useHistory();
  const [SelectedMenuKey, setSelectedMenuKey] = useState("");

  const rootSubmenuKeys = ["Administrator", "TagesabrechnungSub"];
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    switch (history.location.pathname) {
      case "/":
      case "Tagesabrechnung/LocalUnits":
        setSelectedMenuKey("Tagesabrechnung/LocalUnits");
        setOpenKeys(["TagesabrechnungSub"]);
        break;
      case "Tagesabrechnung/BusinessUnits":
        setSelectedMenuKey("Tagesabrechnung/BusinessUnits");
        setOpenKeys(["TagesabrechnungSub"]);
        break;
      case "Tagesabrechnung/WeeklyView":
        setSelectedMenuKey("Tagesabrechnung/WeeklyView");
        setOpenKeys(["TagesabrechnungSub"]);
        break;
      case "/Employee":
        setSelectedMenuKey("Employee");
        break;
      case "/TimeManagement":
        setSelectedMenuKey("TimeManagement");
        break;
      case "/LocalUnits":
        setSelectedMenuKey("LocalUnits");
        setOpenKeys(["Administrator"]);
        break;
      case "/EinnahmenAusgaben":
        setSelectedMenuKey("Income");
        setOpenKeys(["Administrator"]);

        break;
      case "/AccountCategories":
        setSelectedMenuKey("AccountCategories");
        setOpenKeys(["Administrator"]);

        break;
      case "Tagesabrechnung/WorkControl":
        setSelectedMenuKey("Tagesabrechnung/WorkControl");
        setOpenKeys(["Administrator"]);
        break;
      case "/PaymentAccount":
        setSelectedMenuKey("Payment");
        setOpenKeys(["Administrator"]);
        break;
      case "/Eingabemaske":
        setSelectedMenuKey("InputMask");
        setOpenKeys(["Administrator"]);
        break;
      case "/SalesAccount":
        setSelectedMenuKey("Sales");
        setOpenKeys(["Administrator"]);
        break;
      case "/UserManagement":
        setSelectedMenuKey("User");
        setOpenKeys(["Administrator"]);
        break;
      case "/WorkhoursPercentage":
        setSelectedMenuKey("User");
        setOpenKeys(["Administrator"]);
        break;
      case "/AbsenceManagement":
        setSelectedMenuKey("Absence");
      case "/ValueMapping":
        setSelectedMenuKey("ValueMapping");
        break;
    }
  }, []);

  const handleMenuClick = (e) => {
    setSelectedMenuKey(e.key);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const menus = [
    {
      key: "Tagesabrechnung",
      user_types: AuthenticatedUsers["Tagesabrechnung"],
      component: (
        <SubMenu key="TagesabrechnungSub" icon={<DashboardOutlined />} title={t("Tagesabrechnung")}>
          <Menu.Item key="Tagesabrechnung/LocalUnits">
            <Link to={menuRoutes["Tagesabrechnung/LocalUnits"]}>{t("Local Units")}</Link>
          </Menu.Item>
          <Menu.Item key="Tagesabrechnung/BusinessUnits">
            <Link to={menuRoutes["Tagesabrechnung/BusinessUnits"]}>{t("BusinessUnits")}</Link>
          </Menu.Item>
          <Menu.Item key="Tagesabrechnung/WeeklyView">
            <Link to={menuRoutes["Tagesabrechnung/WeeklyView"]}>{t("Weekly View")}</Link>
          </Menu.Item>
        </SubMenu>
      ),
    },
    {
      key: "Employee",
      user_types: AuthenticatedUsers["Employee"],
      component: (
        <Menu.Item key="Employee" icon={<ApartmentOutlined />}>
          <Link to={menuRoutes["Employee"]}>{t("Employee Administration")}</Link>
        </Menu.Item>
      ),
    },
    {
      key: "TimeManagement",
      user_types: AuthenticatedUsers["TimeManagement"],
      component: (
        <SubMenu key="TimeRecording" icon={<FieldTimeOutlined />} title={t("Time Recording")}>
          <Menu.Item key="TimeManagement">
            <Link to={menuRoutes["TimeManagement"]}>{t("Time Tracking")}</Link>
          </Menu.Item>
          <Menu.Item key="InputMask" hidden={props.user.user_type_id == 1 || props.user.user_type_id == 4 || props.user.user_type_id == 5 ? false : true}>
            <Link to={menuRoutes["InputMask"]}>{t("Input Mask")}</Link>
          </Menu.Item>
          <Menu.Item key="WorkControl" hidden={props.user.user_type_id == 1 || props.user.user_type_id == 4 || props.user.user_type_id == 5 ? false : true}>
            <Link to={menuRoutes["WorkControl"]}>{t("Work Control")}</Link>
          </Menu.Item>
        </SubMenu>
      ),
    },
    {
      key: "Absence",
      user_types: AuthenticatedUsers["Absence"],
      component: (
        <Menu.Item icon={<UserSwitchOutlined />} key="Absence">
          <Link to={menuRoutes["Absence"]}>{t("Absence Management")}</Link>
        </Menu.Item>
      ),
    },
    {
      key: "ValueMapping",
      user_types: AuthenticatedUsers["ValueMapping"],
      component: (
        <Menu.Item icon={<AuditOutlined />} key="ValueMapping">
          <Link to={menuRoutes["ValueMapping"]}>{t("Value Mapping")}</Link>
        </Menu.Item>
      ),
    },
    {
      key: "Administrator",
      user_types: AuthenticatedUsers["Administrator"],
      component: (
        <SubMenu key="Administrator" icon={<UserOutlined />} title={t("Administrator")}>
          <Menu.Item key="LocalUnits">
            <Link to={menuRoutes["LocalUnits"]}>{t("Local Units")}</Link>
          </Menu.Item>
          <Menu.Item key="Income">
            <Link to={menuRoutes["Income"]}>{t("Income / Expenses")}</Link>
          </Menu.Item>
          <Menu.Item key="AccountCategories">
            <Link to={menuRoutes["AccountCategories"]}>{t("Account Categories")}</Link>
          </Menu.Item>
          <Menu.Item key="Payment">
            <Link to={menuRoutes["Payment"]}>{t("Payment Account")}</Link>
          </Menu.Item>
          <Menu.Item key="Sales">
            <Link to={menuRoutes["Sales"]}>{t("Sales Account")}</Link>
          </Menu.Item>
          <Menu.Item key="User">
            <Link to={menuRoutes["User"]}>{t("User Administration")}</Link>
          </Menu.Item>
          <Menu.Item key="WorkHoursPercentage">
            <Link to={menuRoutes["WorkHoursPercentage"]}>{t("Arbeitsstunden Prozentsatz")}</Link>
          </Menu.Item>

          {/* <Menu.Item key='Absence']}>{t('Absence Management')}</Menu.Item> */}
        </SubMenu>
      ),
    },
  ];

  return (
    <div style={{ overflowY: "auto", flex: "1 1 auto" }}>
      <Menu openKeys={openKeys} onOpenChange={onOpenChange} selectedKeys={[SelectedMenuKey]} mode="inline" onClick={handleMenuClick}>
        {menus.filter((e) => e.user_types.includes(props.user.user_type_id)).map((e) => e.component)}
      </Menu>
    </div>
  );
}

export default AdminMenu;
