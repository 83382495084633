import { types, flow, getRoot, applySnapshot } from "mobx-state-tree";
import GroupAccountModel from "../Groups/GroupAccountModel";
import BusinessUnitsModel from "../BusinessUnits/BusinessUnitsModel";
import PaymentTypesModel from "./PaymentTypesModel";
import DropdownSettingModel from "../DropdownSettings/DropdownSettingModel";
import BillingAccountValuesModel from "../Tagesabrechnung/BillingAccountValuesModel";
import axios from "axios";
import OrderDetailsModel from "./OrderDetailsModel";
import AccountDebitValuesModel from "./AccountDebitValuesModel";

const AccountsModel = types
   .model("AccountModel", {
      id: types.optional(types.number, 0),
      payment_type_id: types.optional(types.maybeNull(types.number), 0),
      account_type_id: types.optional(types.maybeNull(types.number), 0),
      settings_id: types.optional(types.maybeNull(types.number), 0),
      order_no: types.optional(types.maybeNull(types.number), 0),
      name: types.optional(types.string, ""),
      credit: types.optional(types.maybeNull(types.string), ""),
      debit: types.optional(types.maybeNull(types.string), ""),
      kostentelle: types.optional(types.maybeNull(types.number), 0),
      isActive: types.optional(types.number, 1),
      qty: types.optional(types.number, 0),
      amount: types.optional(types.number, 0),
      GroupAccounts: types.optional(GroupAccountModel, {}),
      OrderDetails: types.optional(types.maybeNull(OrderDetailsModel), {}),
      isIncome: types.optional(types.maybeNull(types.number), 1),
      Settings: types.optional(types.maybeNull(DropdownSettingModel), {}),
      PaymentType: types.optional(types.maybeNull(PaymentTypesModel), {}),
      BusinessUnits: types.optional(types.array(BusinessUnitsModel), []),
      Values: types.optional(types.maybeNull(types.array(BillingAccountValuesModel)), []),
      AccountDebitValues: types.optional(types.array(AccountDebitValuesModel), []),
   })
   .views((self) => ({
      get index() {
         return self.order_no;
      },
      getQty(id, column_id) {
         const BillingAccount = getRoot(self)
            .billings.billing.BillingAccounts.toJSON()
            .find((e) => {
               return e.account_id === id;
            });
         const AnzahlGast = getRoot(self).billings.billing.BillingStaticAccounts.toJSON()[0];
         const value = !!BillingAccount
            ? (BillingAccount.Values.toJSON().find((e) => e.billing_account_columns_id === column_id) || { qty: 0 }).qty
            : !!AnzahlGast
            ? (AnzahlGast.Values.toJSON().find((e) => e.billing_account_columns_id === column_id) || { qty: 0 }).qty
            : 0;
         return value;
      },

      getAmount(id, column_id) {
         const BillingAccount = getRoot(self)
            .billings.billing.BillingAccounts.toJSON()
            .find((e) => e.account_id === id);
         const values = !!BillingAccount
            ? (BillingAccount.Values.toJSON().find((e) => e.billing_account_columns_id === column_id) || { amount: 0 }).amount || 0
            : 0;
         return values;
      },

      get businessUnitNames() {
         return this.BusinessUnits.map((e) => e.name).join(", ");
      },
   }))
   .actions((self) => ({
      SET(property, value) {
         self[property] = value;
      },

      ADD_DEBIT: flow(function* (payload, accountId) {
         const { data } = yield axios.post("/api/accounts/debit", { accountId, data: payload });

         self.AccountDebitValues = data;
      }),

      RESET_ACCOUNT(value) {
         self.state = [];
      },

      SET_UPDATED_AT() {
         self.updatedAt = new Date().getTime().toString();
      },

      UPDATE: flow(function* (values) {
         const { data } = yield axios.put(`/api/accounts/${self.id}`, values);
         applySnapshot(self, data);
      }),

      TOGGLE_STATUS: flow(function* (id, values) {
         const { data } = yield axios.put(`/api/accounts/${id}`, values);
         self.isActive = values.isActive;

         self.SET_UPDATED_AT();
      }),
   }));

export default AccountsModel;
