import { types } from 'mobx-state-tree';

const GroupAccountModel = types
	.model('GroupAccountModel', {
		id: types.optional(types.number, 0),
		name: types.optional(types.string, ''),
		account_id: types.optional(types.number, 0),
		group_id: types.optional(types.number, 0),
		order_no: types.optional(types.number, 0),
		isActive: types.optional(types.number, 1),
	})
	.actions((self) => ({
		SET(property, value) {
			self[property] = value;
		},
	}));

export default GroupAccountModel;
