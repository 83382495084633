import { types, flow, cast } from "mobx-state-tree";
import BusinessUnitsModel from "../BusinessUnits/BusinessUnitsModel";
import DropdownSettingModel from "../DropdownSettings/DropdownSettingModel";
import HomeBaseModel from "./HomeBaseModel";
import moment from "moment";
import axios from "axios";

export default types
  .model("EmployeeModel", {
    BusinessUnits: types.optional(types.array(BusinessUnitsModel), []),
    Settings: types.optional(types.array(DropdownSettingModel), []),
    PersonalCategory: types.optional(types.maybeNull(DropdownSettingModel), {}),
    HomeBase: types.optional(types.maybeNull(HomeBaseModel), {}),
    street: types.optional(types.string, ""),
    zip: types.optional(types.string, ""),
    rate: types.optional(types.maybeNull(types.number), 0),
    address: types.optional(types.string, ""),
    employee_nr: types.optional(types.string, ""),
    employee_category: types.optional(types.maybeNull(types.string), ""),
    id: types.optional(types.number, 0),
    mobile_no: types.optional(types.maybeNull(types.string), ""),
    first_name: types.optional(types.string, ""),
    last_name: types.optional(types.string, ""),
    work_name: types.optional(types.string, ""),
    local_unit_id: types.optional(types.maybeNull(types.number), 0),
    default_business_unit_id: types.optional(types.number, 0),
    default_settings_id: types.optional(types.maybeNull(types.number), 0),
    valid_from: types.optional(types.maybeNull(types.string), ""),
    isCopy: types.optional(types.maybeNull(types.number), 0),
    start_date: types.optional(types.maybeNull(types.string), ""),
    end_date: types.optional(types.maybeNull(types.string), ""),
    comment: types.optional(types.maybeNull(types.string), ""),
    birth_date: types.optional(types.maybeNull(types.string), ""),
    ahv_nr: types.optional(types.maybeNull(types.string), ""),
    h_woche: types.optional(types.maybeNull(types.number), 0),
    ferien_gutschrift: types.optional(types.maybeNull(types.number), 0),
    disabled: types.optional(types.number, 0),
  })
  .views((self) => ({
    get fullname() {
      return self.first_name + " " + self.last_name;
    },

    get isActive() {
      return (!!this.BusinessUnits ? this.BusinessUnits.some((e) => e.EmployeeBusinessUnits.isActive) : false) ? 1 : 0;
    },

    get localUnitName() {
      return this.HomeBase ? this.HomeBase.name : "";
    },

    // get local_unit_id() {
    //   return this.BusinessUnits.length !== 0 ? this.BusinessUnits[0].LocalUnit.id : null;
    // },

    get personalCategory() {
      return this.PersonalCategory?.name;
    },

    get businessUnitsName() {
      return this.BusinessUnits.map((e) => e.name).join(", ");
    },

    get settingsName() {
      return this.Settings.map((e) => e.name).join(", ");
    },

    get disabledBusinessUnits() {
      return this.BusinessUnits.filter((e) => e.disabled);
    },

    get validFrom() {
      return this.valid_from !== "" ? moment(self.valid_from, "DD.MM.YYYY").format("DD.MM.YYYY") : moment().format("DD.MM.YYYY");
    },
  }))
  .actions((self) => ({
    UPDATE: flow(function* (id, values) {
      const { data } = yield axios.put(`/api/employees/${id}`, values);
      delete data.isActive;
      self = Object.assign(self, data);
    }),

    UPDATE_STATUS: flow(function* (values) {
      console.log(values);
      yield axios.post(`/api/employees/status`, values);
    }),
    FETCH_VALID_FROM_DATA: flow(function* (values) {
      // yield axios.post(`/api/employees/status`, values);
    }),
    REMOVE_BUSINESS_UNIT(record) {
      self.BusinessUnits.remove(record);
    },
  }));
