import { types, flow, getRoot } from "mobx-state-tree";

export default types
  .model("TimeCorrectionsListModel", {
    id: types.optional(types.number, 0),
    employee_id: types.optional(types.maybeNull(types.number), 0),
    hours: types.optional(types.maybeNull(types.number), 0),
    correction_from: types.optional(types.string, ""),
    correction_to: types.optional(types.string, ""),
    isCopy: types.optional(types.number, 0),
    comment: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
  })
  .views((self) => ({}));
